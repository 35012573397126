import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import { gql } from '@apollo/client';
import { ProductDetailsFragment } from './productDetailFragment.gql';
export var GET_STORE_CONFIG_DATA = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query getStoreConfigData {\n        # eslint-disable-next-line @graphql-eslint/require-id-when-available\n        storeConfig {\n            store_code\n            product_url_suffix\n            sitewide_discount\n        }\n    }\n"])));
export var GET_PRODUCT_DETAIL_QUERY = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query getProductDetailForProductPage($urlKey: String!) {\n        products(filter: { url_key: { eq: $urlKey } }) {\n            items {\n                id\n                uid\n                ...ProductDetailsFragment\n            }\n        }\n    }\n    ", "\n"])), ProductDetailsFragment);
export var GET_PRODUCT_DETAIL_QUERY_BY_SKU = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query getProductDetailForProductPage($sku: String!) {\n        products(filter: { sku: { eq: $sku } }) {\n            items {\n                id\n                uid\n                ...ProductDetailsFragment\n            }\n        }\n    }\n    ", "\n"])), ProductDetailsFragment);
export default {
  getStoreConfigData: GET_STORE_CONFIG_DATA,
  getProductDetailQuery: GET_PRODUCT_DETAIL_QUERY,
  getProductDetailQueryBySku: GET_PRODUCT_DETAIL_QUERY_BY_SKU
};