import * as Sentry from '@sentry/react';
var env = process.env.MAGENTO_ENV;
var SentryErrorLogger = err => {
  if (env !== 'development') {
    var errorValue = Array.isArray(err) && err[0] ? err[0].message : err;
    if (typeof errorValue === "object" && errorValue.hasOwnProperty('message')) {
      errorValue = errorValue.message;
    }
    Sentry.captureException(errorValue);
  }
};
export { SentryErrorLogger };