import { useExperienceContext } from '../contexts/useExperienceContext';
export var useExperience = () => {
  var {
    sdkContext,
    setSdkContext
  } = useExperienceContext();
  return {
    sdkContext,
    setSdkContext
  };
};