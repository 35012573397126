import styled from 'styled-components';
import { DropDownButtonStyled, DropDownContentStyled } from "../../../../components/HeaderStaticBlock/HeaderStaticBlock.styled";
export var StyledNavigation = styled.aside.withConfig({
  displayName: "navigationstyled__StyledNavigation",
  componentId: "sc-1vq8ydc-0"
})(["", ";transform:translate3d(-100%,0,0);transition-property:opacity,transform,visibility;top:", ";&.root_open{transform:translate3d(0,0,0);}&.enter{transition-duration:384ms;transition-timing-function:cubic-bezier(0,0,0.2,1);}&.exit{transition-duration:192ms;transition-timing-function:cubic-bezier(0.4,0,1,1);}&.hidden{opacity:0;visibility:hidden;}&.visible{opacity:1;visibility:visible;}.header{", ";height:3.5rem;position:relative;z-index:1;}.body{min-height:0;overflow:auto;transition-property:opacity,visibility;}.body_masked{}.footer{", "}.modal{background-color:white;bottom:0;left:0;overflow:auto;position:absolute;right:0;top:3.5rem;transform:translate3d(-100%,0,0);transition-property:opacity,transform,visibility;&.modal_open{opacity:1;visibility:visible;transition-duration:384ms;transition-timing-function:cubic-bezier(0,0,0.2,1);transform:translate3d(0,0,0);}}.switchers{display:grid;grid-auto-flow:column;justify-content:space-between;width:100%;}.nav-content-wrapper{", ";.contact-buttons-wrapper{", ";}}.main-nav-mobile{", ";ul{", ";li{", ";a{}&:first-child{", ";}}}.dropdown{display:flex;flex-direction:column;align-items:center;}", "{svg{font-size:14px;}}", "{position:static;background:none;box-shadow:none;ul{li{justify-content:center !important;&:not(:last-child){margin-bottom:0;}a{color:rgb(242 242 242 / 76%);}}}}}}"], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(17, 17, 17, var(--tw-bg-opacity))",
  "top": "3.5rem",
  "left": "0px",
  "bottom": "0px",
  "zIndex": "11",
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-white) / var(--tw-text-opacity))",
  "position": "fixed",
  "overflow": "hidden",
  "width": "100%",
  "display": "flex",
  "opacity": "0",
  "flexDirection": "column"
}, props => props.headerHeight + 'px', {
  "display": "flex",
  "justifyContent": "space-between",
  "alignContent": "center"
}, {
  "height": "0.5rem"
}, {
  "display": "flex",
  "flexDirection": "row",
  "flexWrap": "wrap",
  "flex": "1 1 0%",
  "alignItems": "flex-start",
  "justifyContent": "center",
  "overflowX": "auto"
}, {
  "flex": "1 1 0%",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "width": "100%"
}, {
  "display": "flex",
  "flexDirection": "column",
  "padding": "0px",
  "margin": "0px",
  "width": "100%",
  "textAlign": "center",
  "fontFamily": "HK Nova, sans-serif"
}, {
  "display": "flex",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "1rem",
  "paddingBottom": "0px",
  "marginBottom": "0px",
  "width": "100%",
  "textAlign": "center"
}, {
  "display": "flex",
  "paddingTop": "1.25rem",
  "paddingBottom": "1.25rem",
  "paddingLeft": "0px",
  "paddingRight": "0px",
  "width": "100%",
  "borderTopWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(var(--color-gray-800) / var(--tw-border-opacity))",
  "textTransform": "uppercase",
  "justifyContent": "center",
  "fontWeight": "500",
  "fontSize": "1.25rem",
  "lineHeight": "1.75rem"
}, {
  "borderWidth": "0px"
}, DropDownButtonStyled, DropDownContentStyled);