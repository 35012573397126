/**
 * Maps an error to a string message
 *
 * @param {Error} error the error to map
 * @return {String} error message
 */

var toString = (error, defaultErrorMessage) => {
  var {
    graphQLErrors,
    message
  } = error;
  if (graphQLErrors && graphQLErrors.length) {
    if (defaultErrorMessage) {
      return defaultErrorMessage;
    }
    return graphQLErrors.map(_ref => {
      var {
        message,
        debugMessage
      } = _ref;
      if (debugMessage) {
        return debugMessage;
      }
      return message;
    }).join(', ');
  }
  return message;
};

/**
 * A function to derive an error string from an array of errors.
 */
export var deriveErrorMessage = function (errors) {
  var defaultErrorMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var errorCollection = [];
  for (var error of errors) {
    if (error) {
      errorCollection.push(toString(error, defaultErrorMessage));
    }
  }
  return errorCollection.join(', ');
};