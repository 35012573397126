// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".indicator-root--zy {\n    align-content: center;\n    align-items: center;\n    display: grid;\n    gap: 3rem;\n    height: calc(100% - 6rem);\n    justify-content: center;\n    justify-items: center;\n    margin: 3rem 0;\n    width: 100%;\n}\n\n.indicator-global-0xC {\n    position: fixed;\n    top: 0;\n    left: 0;\n    margin: 0;\n    height: 100%;\n    width: 100%;\n}\n\n.indicator-global-0xC .indicator-message-MH2 {\n    color: #fff;\n    font-family: \"HK Nova\", sans-serif;\n    font-weight: 500;\n    font-size: 1rem;\n    letter-spacing: 0.1rem;\n    text-transform: capitalize;\n    border: none;\n}\n\n.indicator-message-MH2 {\n    color: rgb(var(--venia-global-color-text-alt));\n    font-family: \"HK Nova\", sans-serif;\n    font-weight: 500;\n    font-size: 1rem;\n    letter-spacing: 0.1rem;\n    text-transform: capitalize;\n}\n\n.indicator-indicator-QMz {\n    animation-direction: alternate;\n    animation-duration: 1.5s;\n    animation-iteration-count: infinite;\n    animation-name: indicator-pulse-KJI;\n    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n}\n\n@keyframes indicator-pulse-KJI {\n    0% {\n        filter: grayscale(1);\n        transform: scale(0.75) rotate(-540deg);\n    }\n    100% {\n        filter: grayscale(0);\n        transform: scale(1) rotate(0deg);\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "indicator-root--zy",
	"global": "indicator-global-0xC indicator-root--zy",
	"message": "indicator-message-MH2",
	"indicator": "indicator-indicator-QMz",
	"pulse": "indicator-pulse-KJI"
};
export default ___CSS_LOADER_EXPORT___;
