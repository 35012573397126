export var SET_SELECTION = 'SET_SELECTION';
export var REMOVE_SELECTION = 'REMOVE_SELECTION';
export var SET_SMART_SELECTION = 'SET_SMART_SELECTION';
export var REMOVE_SMART_SELECTION = 'REMOVE_SMART_SELECTION';
export var ADD_ADDITIONAL_PRODUCT = 'ADD_ADDITIONAL_PRODUCT';
export var REMOVE_ADDITIONAL_PRODUCT = 'REMOVE_ADDITIONAL_PRODUCT';
export var CLEAR_ADDITIONAL_PRODUCTS = 'CLEAR_ADDITIONAL_PRODUCTS';
export var SET_PROCEED_WITH_INVALID_MOUNT = 'SET_PROCEED_WITH_INVALID_MOUNT';
export var CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export var CLEAR_PRODUCT_PARTIALLY = 'CLEAR_PRODUCT_PARTIALLY';
export var POPULATE_EDIT_PRODUCT_DATA = 'POPULATE_EDIT_PRODUCT_DATA';
export var REMOVE_MULTIPLE_SELECTION = 'REMOVE_MULTIPLE_SELECTION';
export var HAS_FILLED_MEASURES_IN_PDP = 'HAS_FILLED_MEASURES_IN_PDP';
export var MODE = {
  ADD: 'ADD',
  EDIT: 'EDIT'
};