// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../../../node_modules/postcss-loader/dist/cjs.js!../Field/field.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textInput-input-1Kl {\n}\n\n.textInput-input-1Kl:disabled {\n    color: rgb(var(--venia-global-color-gray-darker));\n}\n\n.textInput-input_error-dRC {\n    border-color: rgb(var(--venia-global-color-red-400));\n}\n\n.textInput-input_shimmer-pMR {\n}\n\n.textInput-input-1Kl:-moz-read-only {\n    background-color: #dfdfdf;\n}\n\n.textInput-input-1Kl:read-only {\n    background-color: #dfdfdf;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "textInput-input-1Kl " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["input"] + "",
	"input_error": "textInput-input_error-dRC " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["input"] + "",
	"input_shimmer": "textInput-input_shimmer-pMR " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["input_base"] + ""
};
export default ___CSS_LOADER_EXPORT___;
