import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { getAdvanced } from '@magento/pagebuilder/lib/utils';
export default ((node, props) => {
  var forms = node.querySelectorAll('.product-item-details > .product-item-name > a.product-item-link');
  var carouselConfig = {};
  if (props.appearance === 'carousel') {
    carouselConfig = {
      autoplay: node.getAttribute('data-autoplay') === 'true',
      autoplaySpeed: parseInt(node.getAttribute('data-autoplay-speed')),
      infinite: node.getAttribute('data-infinite-loop') === 'true',
      arrows: node.getAttribute('data-show-arrows') === 'true',
      dots: node.getAttribute('data-show-dots') === 'true',
      carouselMode: node.getAttribute('data-carousel-mode'),
      centerPadding: node.getAttribute('data-center-padding')
    };
  }
  return _objectSpread(_objectSpread({
    pathNames: [...forms].map(form => form.getAttribute('href'))
  }, carouselConfig), getAdvanced(node));
});