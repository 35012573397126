import { useBannerContext } from "../contexts/useBannerContext";
export var useBanner = () => {
  var {
    isSticky,
    showBanner,
    setShowBanner,
    bannerRef
  } = useBannerContext();
  return {
    isSticky,
    showBanner,
    setShowBanner,
    bannerRef
  };
};