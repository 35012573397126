// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radio-root-Eu0 {\n    align-items: center;\n    color: rgb(var(--venia-global-color-gray-900));\n    display: grid;\n    gap: 1.25rem;\n    grid-auto-flow: column;\n    grid-template-areas: 'input label';\n    grid-template-columns: min-content 1fr;\n    margin: 0;\n    justify-items: center;\n    line-height: 1.5rem;\n}\n\n.radio-input_base-rTp {\n    border-radius: 50%;\n    height: 1.5rem;\n    margin: 0;\n    width: 1.5rem;\n    z-index: 2;\n}\n\n.radio-input-xBC {\n    -webkit-appearance: none;\n    background: none;\n    background-clip: content-box;\n    border: 0.438rem solid transparent;\n    grid-area: input;\n}\n\n.radio-icon-fHI {\n    grid-area: input;\n    height: 1.5rem;\n    width: 1.5rem;\n    z-index: 1;\n}\n\n.radio-icon-fHI svg {\n    stroke: rgb(var(--venia-global-color-gray-600));\n}\n\n.radio-label-F27 {\n    font-size: var(--venia-typography-body-S-fontSize);\n    grid-area: label;\n    justify-self: start;\n}\n\n.radio-input-xBC:checked {\n    background-color: transparent;\n}\n\n.radio-input-xBC:checked + .radio-icon-fHI svg {\n    stroke: rgb(var(--venia-brand-color-1-700));\n}\n\n.radio-input-xBC:active,\n.radio-input-xBC:focus {\n    box-shadow: none;\n    outline: none;\n    background-color: transparent;\n}\n.radio-input-xBC:hover {\n    cursor: pointer;\n}\n\n.radio-input-xBC + .radio-icon-fHI {\n    grid-area: input;\n    height: 1.75rem;\n    pointer-events: none;\n    width: 1.75rem;\n    border: 0.063rem solid #dbdbdb;\n    border-radius: 0.375rem;\n    background-color: #f7f7f7;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n}\n.radio-input-xBC + .radio-icon-fHI svg {\n    stroke: #f7f7f7;\n}\n\n.radio-input-xBC:checked + .radio-icon-fHI {\n    background-color: #121212;\n    padding: 2px;\n}\n\n.radio-input-xBC:checked + .radio-icon-fHI svg {\n    stroke: #fff;\n}\n\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "radio-root-Eu0",
	"input_base": "radio-input_base-rTp",
	"input": "radio-input-xBC radio-input_base-rTp",
	"icon": "radio-icon-fHI",
	"label": "radio-label-F27"
};
export default ___CSS_LOADER_EXPORT___;
