import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { getAdvanced } from '@magento/pagebuilder/lib/utils';
export default (node => {
  var autoplaySpeed = parseInt(node.getAttribute('data-autoplay-speed'));
  return _objectSpread(_objectSpread({
    minHeight: node.style.minHeight,
    autoplay: node.getAttribute('data-autoplay') === 'true',
    fade: node.getAttribute('data-fade') === 'true',
    infinite: node.getAttribute('data-infinite-loop') === 'true',
    showArrows: node.getAttribute('data-show-arrows') === 'true',
    showDots: node.getAttribute('data-show-dots') === 'true',
    dataSlidersMobile: node.getAttribute('data-sliders-mobile'),
    dataSlidersTab: node.getAttribute('data-sliders-tab'),
    dataSlidersDesktop: node.getAttribute('data-sliders-desktop')
  }, !isNaN(autoplaySpeed) && {
    autoplaySpeed
  }), getAdvanced(node));
});