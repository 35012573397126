// source: https://gist.github.com/reecelucas/2f510e6b8504008deaaa52732202d2da

import { useRef } from 'react';
var safeDocument = typeof document !== 'undefined' ? document : {};
export default (() => {
  var scrollBlocked = useRef();
  var html = safeDocument.documentElement;
  var {
    body
  } = safeDocument;
  var blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;
    var scrollBarWidth = window.innerWidth - html.clientWidth;
    var bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;
    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    body.style.paddingRight = "".concat(bodyPaddingRight + scrollBarWidth, "px");
    scrollBlocked.current = true;
  };
  var allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;
    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';
    scrollBlocked.current = false;
  };
  return [blockScroll, allowScroll];
});