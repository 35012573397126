// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorView-root-q2k {\n    width: 100vw;\n    transform: translateX(-50%);\n    margin-left: 50%;\n    background: #000;\n    min-height: 100vh !important;\n}\n\n.errorView-content-Bj8 {\n    transform: none;\n    margin-left: auto !important;\n    margin-right: auto !important;\n    max-width: var(--venia-global-maxWidth, 1280px);\n    width: 100%;\n    padding: 5rem 0 8rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.errorView-header-S-t {\n    font-size: var(--venia-global-fontSize-1000);\n    font-weight: var(--venia-global-fontWeight-semibold);\n}\n\n.errorView-message-1-9 {\n    line-height: var(--venia-global-lineHeight-200);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "errorView-root-q2k",
	"content": "errorView-content-Bj8 errorView-root-q2k",
	"header": "errorView-header-S-t",
	"message": "errorView-message-1-9"
};
export default ___CSS_LOADER_EXPORT___;
