import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React, { useEffect, useState } from 'react';
import { getConfigObjectById } from "../utils/product";
import { useHistory, useLocation } from "react-router-dom";
var useHelpPopUp = props => {
  var [helpDetail, setHelpDetail] = useState(null);
  var [isModalOpen, setIsModalOpen] = useState(false);
  var {
    pathname,
    search
  } = useLocation();
  var history = useHistory();
  var {
    helpParameter,
    helpConfigArray
  } = props;
  useEffect(() => {
    var params = new URLSearchParams(search);
    if (params.has(helpParameter)) {
      var configObjectId = params.get(helpParameter);
      setHelpDetail(_objectSpread({}, getConfigObjectById([...helpConfigArray], configObjectId)));
      setIsModalOpen(true);
    }
  }, []);
  var setHelpRouteParameter = (id, existingUrlParameters) => {
    var parametersObject = _objectSpread({
      [helpParameter]: id
    }, existingUrlParameters);
    var params = new URLSearchParams(_objectSpread({}, parametersObject));
    history.replace({
      pathname: pathname,
      search: params.toString()
    });
  };
  var removeQueryParams = () => {
    var params = new URLSearchParams(search);
    if (params.has(helpParameter)) {
      params.delete(helpParameter);
      history.replace({
        search: params.toString()
      });
    }
  };
  return {
    helpDetail,
    setHelpDetail,
    isModalOpen,
    setIsModalOpen,
    setHelpRouteParameter,
    removeQueryParams
  };
};
export default useHelpPopUp;