// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-root-Ojt {\n    border-radius: 10rem;\n    border-style: solid;\n    border-width: 2px;\n    line-height: 1.25rem;\n    max-width: 100%;\n    min-width: 10rem;\n    min-height: 2.5rem;\n    outline: none;\n    padding: calc(0.5rem + 1px) 1.5rem calc(0.5rem - 1px);\n    text-transform: uppercase;\n    transition-duration: 256ms;\n}\n\n.button-root-Ojt:hover {\n    /* --stroke: var(--venia-brand-color-1-800); */\n}\n\n.button-root-Ojt:focus {\n    /* box-shadow: -6px 6px rgb(var(--venia-brand-color-1-100)); */\n}\n\n.button-root-Ojt:active {\n    transition-duration: 128ms;\n    /* --stroke: var(--venia-brand-color-1-800); */\n}\n\n/**\n * Some browsers retain the :hover state after a click, this ensures if a button becomes disabled after\n * being clicked it will be visually disabled.\n */\n.button-root-Ojt:disabled,\n.button-root-Ojt:hover:disabled {\n    pointer-events: none;\n    /* --stroke: var(--venia-global-color-gray-400); */\n}\n\n.button-content-krs {\n    justify-content: center;\n    grid-auto-flow: column;\n    justify-items: center;\n    display: inline-grid;\n    align-items: center;\n    gap: 0.35rem;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "button-root-Ojt",
	"content": "button-content-krs"
};
export default ___CSS_LOADER_EXPORT___;
