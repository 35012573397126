import React from 'react';
import Row from '@magento/pagebuilder/lib/ContentTypes/Row';
import columnConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Column/configAggregator';
import Column from '@magento/pagebuilder/lib/ContentTypes/Column';
import columnGroupConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/ColumnGroup/configAggregator';
import ColumnGroup from '@magento/pagebuilder/lib/ContentTypes/ColumnGroup';
import imageConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Image/configAggregator';
import Image from '@magento/pagebuilder/lib/ContentTypes/Image';
import headingConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Heading/configAggregator';
import Heading from '@magento/pagebuilder/lib/ContentTypes/Heading';
import textConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Text/configAggregator';
import Text from '@magento/pagebuilder/lib/ContentTypes/Text';
import tabsConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Tabs/configAggregator';
import tabItemConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/TabItem/configAggregator';
import blockConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Block/configAggregator';
import productsConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Products/configAggregator';
import buttonsConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Buttons/configAggregator';
import buttonItemConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/ButtonItem/configAggregator';
import htmlConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Html/configAggregator';
import dividerConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Divider/configAggregator';
// import videoConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Video/configAggregator';
import mapConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Map/configAggregator';
import bannerConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Banner/configAggregator';
import { BannerShimmer } from '@magento/pagebuilder/lib/ContentTypes/Banner';
import ButtonItem from '@magento/pagebuilder/lib/ContentTypes/ButtonItem';
import sliderConfigAggregator from '@magento/pagebuilder/lib/ContentTypes/Slider/configAggregator';
import { SliderShimmer } from '@magento/pagebuilder/lib/ContentTypes/Slider';

// ublockout
import videoConfigAggregator from './ContentTypes/Video/configAggregator';
import accordionConfigAggregator from './ContentTypes/Accordion/configAggregator';
import bannerSliderConfigAggregator from './ContentTypes/BannerSlider/configAggregator';
import productsFullDetailsConfigAggregator from './ContentTypes/ProductsFullDetails/configAggregator';
import rowConfigAggregator from './ContentTypes/Row/configAggregator';
import lazyRetry from "../../utils/lazyRetry";

/* istanbul ignore next */
var contentTypesConfig = {
  row: {
    configAggregator: rowConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('./ContentTypes/Row'), 'row'))
  },
  column: {
    configAggregator: columnConfigAggregator,
    component: Column
  },
  'column-group': {
    configAggregator: columnGroupConfigAggregator,
    component: ColumnGroup
  },
  'column-line': {
    configAggregator: columnGroupConfigAggregator,
    component: ColumnGroup
  },
  image: {
    configAggregator: imageConfigAggregator,
    component: Image
  },
  heading: {
    configAggregator: headingConfigAggregator,
    component: Heading
  },
  text: {
    configAggregator: textConfigAggregator,
    component: Text
  },
  tabs: {
    configAggregator: tabsConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Tabs'), 'tabs'))
  },
  'tab-item': {
    configAggregator: tabItemConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/TabItem'), 'tab-item'))
  },
  buttons: {
    configAggregator: buttonsConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Buttons'), 'buttons'))
  },
  'button-item': {
    configAggregator: buttonItemConfigAggregator,
    component: ButtonItem
  },
  block: {
    configAggregator: blockConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Block'), 'block'))
  },
  products: {
    configAggregator: productsConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Products'), 'products'))
  },
  html: {
    configAggregator: htmlConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Html'), 'html'))
  },
  divider: {
    configAggregator: dividerConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Divider'), 'divider'))
  },
  video: {
    configAggregator: videoConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('./ContentTypes/Video'), 'video'))
  },
  map: {
    configAggregator: mapConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Map'), 'map'))
  },
  banner: {
    configAggregator: bannerConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Banner'), 'banner')),
    componentShimmer: BannerShimmer
  },
  slider: {
    configAggregator: sliderConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Slider'), 'slider')),
    componentShimmer: SliderShimmer
  },
  // Slide is just a banner wrapped inside a slider
  slide: {
    configAggregator: bannerConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('@magento/pagebuilder/lib/ContentTypes/Banner'), 'slide')),
    componentShimmer: BannerShimmer
  },
  accordion: {
    configAggregator: accordionConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('./ContentTypes/Accordion'), 'accordion'))
  },
  productsFullDetails: {
    configAggregator: productsFullDetailsConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('./ContentTypes/ProductsFullDetails'), 'productsFullDetails'))
  },
  atomix_banner_slider: {
    configAggregator: bannerSliderConfigAggregator,
    component: React.lazy(() => lazyRetry(() => import('./ContentTypes/BannerSlider'), 'atomixBannerSlider'))
  }
};

/**
 * Retrieve a content types configuration
 *
 * @param {string} contentType
 * @returns {*}
 */
export function getContentTypeConfig(contentType) {
  if (contentTypesConfig[contentType]) {
    return contentTypesConfig[contentType];
  }
}

/**
 * Set content types configuration with new one
 *
 * @param {string} contentType
 * @param {*} config
 * @returns {*}
 */
export function setContentTypeConfig(contentType, config) {
  return contentTypesConfig[contentType] = config;
}