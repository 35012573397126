// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".field-root-BRZ {\n    color: rgb(var(--venia-global-color-text));\n    display: grid;\n    align-content: start;\n}\n\n.field-label-1jr {\n    align-items: center;\n    display: flex;\n    font-size: 1rem;\n    font-weight: var(--venia-global-fontWeight-semibold);\n    line-height: 1rem;\n    padding: 0.5rem 0.125rem .875rem;\n    min-height: 2.125rem;\n}\n\n.field-input_base-fO3 {\n    border-radius: 6px;\n    font-size: 1rem;\n    height: 2.5rem;\n    margin: 0;\n    max-width: 100%;\n    padding: calc(0.375rem - 1px) calc(0.625rem - 1px);\n    width: 100%;\n}\n\n.field-input-1lQ {\n    background: white;\n    border: 2px solid rgb(var(--venia-global-color-gray-600));\n    color: rgb(var(--venia-global-color-text));\n    display: inline-flex;\n    flex: 0 0 100%;\n    -webkit-appearance: none;\n}\n\n.field-input-1lQ:focus {\n    box-shadow: unset;\n    outline: none;\n}\n\n.field-optional-g8e {\n    color: rgb(var(--venia-global-color-gray-700));\n    font-size: var(--venia-global-fontSize-100);\n    font-weight: var(--venia-global-fontWeight-normal);\n}\n\n.field-required-6Kf {\n    color: red;\n    margin-left: 5px;\n    font-size: var(--venia-global-fontSize-100);\n    font-weight: var(--venia-global-fontWeight-normal);\n}\n\n@media (min-width: 1024px) {\n    .field-input_base-fO3 {\n        height: 3.125rem;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "field-root-BRZ",
	"label": "field-label-1jr",
	"input_base": "field-input_base-fO3",
	"input": "field-input-1lQ field-input_base-fO3",
	"optional": "field-optional-g8e",
	"required": "field-required-6Kf"
};
export default ___CSS_LOADER_EXPORT___;
