var useHubspotTrackingCode = () => {
  var _hsq = typeof window !== 'undefined' && window._hsq ? window._hsq : [];
  var setTrackPageView = () => {
    _hsq.push(['trackPageView']);
  };
  var setPathPageView = path => {
    _hsq.push(['setPath', path]);
    setTrackPageView();
  };
  var setCustomBehavioralEvent = (eventName, properties) => {
    _hsq.push(['trackCustomBehavioralEvent', {
      name: eventName,
      properties
    }]);
  };
  return {
    setPathPageView,
    setCustomBehavioralEvent
  };
};
export default useHubspotTrackingCode;