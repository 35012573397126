// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fieldIcons-root-wfG {\n    display: inline-grid;\n    grid-auto-flow: column;\n    grid-template-areas: 'before input after';\n    grid-template-columns: auto 1fr auto;\n    width: 100%;\n}\n\n.fieldIcons-input-6z5 {\n    align-items: center;\n    display: flex;\n    grid-column: before-start / after-end;\n    grid-row: input-start / input-end;\n}\n\n.fieldIcons-input-6z5 > input {\n    padding-left: calc(1.875rem * var(--iconsBefore) + 0.625rem);\n    padding-right: calc(1.875rem * var(--iconsAfter) + 0.625rem);\n}\n\n.fieldIcons-before-gei,\n.fieldIcons-after-FEI {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    margin: 0 2px;\n    pointer-events: none;\n    width: 2.5rem;\n    z-index: 1;\n}\n\n.fieldIcons-before-gei:empty,\n.fieldIcons-after-FEI:empty {\n    display: none;\n}\n\n.fieldIcons-before-gei {\n    grid-area: before;\n}\n\n.fieldIcons-after-FEI {\n    grid-area: after;\n}\n\n.fieldIcons-before-gei svg {\n    stroke: rgb(var(--venia-global-color-gray-600));\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "fieldIcons-root-wfG",
	"input": "fieldIcons-input-6z5",
	"before": "fieldIcons-before-gei",
	"after": "fieldIcons-after-FEI"
};
export default ___CSS_LOADER_EXPORT___;
