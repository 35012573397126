import styled from 'styled-components';
export var StyledAccountChip = styled.span.withConfig({
  displayName: "accountChipstyled__StyledAccountChip",
  componentId: "sc-neqh2d-0"
})(["", " &.root{", "}.text{", "}"], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(17, 17, 17, var(--tw-bg-opacity))",
  "height": "2rem",
  "width": "2rem",
  "borderRadius": "9999px",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "overflow": "hidden"
}, {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(17, 17, 17, var(--tw-bg-opacity))"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-black) / var(--tw-text-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(var(--color-gray-400) / var(--tw-bg-opacity))",
  "width": "100%",
  "height": "100%",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "textTransform": "uppercase",
  "fontWeight": "700"
});