import { CUSTOMIZABLE_OPTION_ATTRIBUTES, OPTION_ATTRIBUTE_CODES } from "../constant";
var HUBSPOT_ID = process.env.HUBSPOT_ID;
var CUSTOM_BEHAVIORAL_EVENTS = {
  BUTTONS: {
    BLACKOUT: {
      CUSTOMIZE_AND_BUY: "pe".concat(HUBSPOT_ID, "_button_click___pdp___customize___buy"),
      BUY_NOW_MEASURE_LATER: "pe".concat(HUBSPOT_ID, "_button_click___pdp___buy_now_measure_later"),
      ADD_TO_CART: "pe".concat(HUBSPOT_ID, "_button_click___add_to_cart___shade")
    },
    ACCESSORIES: {
      ADD_TO_CART: "pe".concat(HUBSPOT_ID, "_add_to_cart___accessory___click")
    }
  },
  SHADE: {
    COLORS: {
      [OPTION_ATTRIBUTE_CODES.FRAME]: "pe".concat(HUBSPOT_ID, "_pdp___frame_color"),
      [OPTION_ATTRIBUTE_CODES.COLOR]: "pe".concat(HUBSPOT_ID, "_pdp___fabric_color")
    },
    MEASURE: "pe".concat(HUBSPOT_ID, "_pdp___measures"),
    MOTORIZED: "pe".concat(HUBSPOT_ID, "_pdp___motorized")
  },
  ORDER_WIZARD: {
    STEP_1: {
      [OPTION_ATTRIBUTE_CODES.FRAME]: "pe".concat(HUBSPOT_ID, "_step_1___order_wizard___frame_color"),
      [OPTION_ATTRIBUTE_CODES.COLOR]: "pe".concat(HUBSPOT_ID, "_step_1___order_wizard___fabric_color")
    },
    STEP_2: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.MOUNT_TYPE]: "pe".concat(HUBSPOT_ID, "_step_2___order_wizard___mount_type"),
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.WINDOW_TYPE]: "pe".concat(HUBSPOT_ID, "_step_2___order_wizard___window_type"),
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.TRIM]: "pe".concat(HUBSPOT_ID, "_step_2___order_wizard___trim")
    },
    STEP_3: {
      MEASURES: "pe".concat(HUBSPOT_ID, "_step_3___order_wizard___measures"),
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.INSURANCE]: "pe".concat(HUBSPOT_ID, "_step_3___measure_guarantee")
    },
    STEP_4: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.CONTROL_TYPE]: "pe".concat(HUBSPOT_ID, "_step_4___order_wizard___control_type"),
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.MOTORIZED_REMOTE_OPTIONS]: "pe".concat(HUBSPOT_ID, "_step_4___order_wizard___remote")
    },
    STEP_5: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.ORIENTATION]: "pe".concat(HUBSPOT_ID, "_step_5___order_wizard___orientation")
    },
    STEP_6: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.WINDOW_NAMES]: "pe".concat(HUBSPOT_ID, "_step_6___order_wizard___window_name")
    }
  },
  CART: {
    SHARE_CART_URL: "pe".concat(HUBSPOT_ID, "_cart___share_cart_url"),
    CHECKOUT: "pe".concat(HUBSPOT_ID, "_button_click___checkout")
  },
  CHECKOUT: {
    INFORMATION: "pe".concat(HUBSPOT_ID, "_checkout___information"),
    DELIVERY: "pe".concat(HUBSPOT_ID, "_checkout___delivery"),
    PAYMENT: "pe".concat(HUBSPOT_ID, "_checkout___payment")
  }
};
var CUSTOM_BEHAVIORAL_EVENTS_PROPERTIES = {
  SHADE: {
    COLORS: {
      [OPTION_ATTRIBUTE_CODES.FRAME]: 'frame_color',
      [OPTION_ATTRIBUTE_CODES.COLOR]: 'fabric_color'
    },
    MEASURES: {
      WIDTH: 'width',
      HEIGHT: 'height'
    },
    MOTORIZED: 'motorized'
  },
  ORDER_WIZARD: {
    STEP_1: {
      [OPTION_ATTRIBUTE_CODES.FRAME]: 'frame_color',
      [OPTION_ATTRIBUTE_CODES.COLOR]: 'fabric_color'
    },
    STEP_2: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.MOUNT_TYPE]: 'mount_type',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.WINDOW_TYPE]: 'window_type',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.TRIM]: 'trim'
    },
    STEP_3: {
      MEASURES: {
        WIDTH: 'width',
        HEIGHT: 'height'
      },
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.INSURANCE]: 'measure_guarantee'
    },
    STEP_4: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.CONTROL_TYPE]: 'control_type',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.MOTORIZED_REMOTE_OPTIONS]: 'remote'
    },
    STEP_5: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.ORIENTATION]: 'orientation'
    },
    STEP_6: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.WINDOW_NAMES]: 'window_name'
    }
  },
  CART: {
    SHARE_CART_URL: 'share_cart_url',
    TOTAL_VALUE: 'total_value'
  }
};
export { CUSTOM_BEHAVIORAL_EVENTS, CUSTOM_BEHAVIORAL_EVENTS_PROPERTIES };