// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cmsBlock-root-sej {\n    margin: 0 1rem 0 0;\n}\n\n.cmsBlock-content-Eab h2 {\n    margin-bottom: 2rem;\n    text-align: center;\n    text-transform: uppercase;\n}\n\n.cmsBlock-content-Eab img {\n    display: block;\n    height: auto;\n    max-height: 100%;\n    max-width: 100%;\n    width: auto;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "cmsBlock-root-sej",
	"content": "cmsBlock-content-Eab"
};
export default ___CSS_LOADER_EXPORT___;
