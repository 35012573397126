import styled from 'styled-components';
import { BuyNowPopupStyled } from "../Product/Content/BuyNowPopup/BuyNowPopup.styled";
export var NeedHelpWrapperStyled = styled.div.withConfig({
  displayName: "NeedHelpstyled__NeedHelpWrapperStyled",
  componentId: "sc-rg17m1-0"
})(["", ";", "{", " .buy-now-btn{", " &:hover{", "}}}}"], {
  "paddingLeft": "1.25rem",
  "paddingRight": "1.25rem",
  "@media (min-width: 1024px)": {
    "paddingLeft": "5rem",
    "paddingRight": "5rem"
  },
  "@media (min-width: 1280px)": {
    "paddingLeft": "9rem",
    "paddingRight": "9rem"
  }
}, BuyNowPopupStyled, {
  "width": "100%",
  "minWidth": "16.875rem",
  "maxWidth": "16.875rem",
  "@media (min-width: 768px)": {
    "width": "auto"
  }
}, {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(23, 94, 183, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
}, {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(20, 89, 175, var(--tw-bg-opacity))"
});