import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { omit, cloneDeep } from 'lodash';
import { SET_SELECTION, SET_SMART_SELECTION, REMOVE_SELECTION, REMOVE_SMART_SELECTION, ADD_ADDITIONAL_PRODUCT, REMOVE_ADDITIONAL_PRODUCT, CLEAR_ADDITIONAL_PRODUCTS, CLEAR_PRODUCT, SET_PROCEED_WITH_INVALID_MOUNT, POPULATE_EDIT_PRODUCT_DATA, REMOVE_MULTIPLE_SELECTION, MODE, CLEAR_PRODUCT_PARTIALLY, HAS_FILLED_MEASURES_IN_PDP } from './constants';
export var initialState = {
  additionalProducts: [],
  canProceedWithInvalidMount: false,
  isExtraConfirmedInMobile: false,
  hasFilledMeasuresInOrderWizard: false,
  mode: MODE.ADD
};
export var reducer = function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var {
    type,
    payload
  } = action;
  switch (type) {
    case SET_SELECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        [payload.attributeCode]: payload.value
      });
    case REMOVE_SELECTION:
      {
        var _payload$property = payload.property,
          {
            [_payload$property]: value
          } = state,
          rest = _objectWithoutProperties(state, [_payload$property].map(_toPropertyKey));
        return _objectSpread({}, rest);
      }
    case SET_SMART_SELECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        [payload.attributeCode]: payload.value
      });
    case REMOVE_SMART_SELECTION:
      {
        var _payload$property2 = payload.property,
          {
            [_payload$property2]: _value
          } = state,
          rest = _objectWithoutProperties(state, [_payload$property2].map(_toPropertyKey));
        return _objectSpread({}, rest);
      }
    case REMOVE_MULTIPLE_SELECTION:
      {
        var clonedState = cloneDeep(state);
        var updatedState = omit(clonedState, payload);
        return _objectSpread({}, updatedState);
      }
    case POPULATE_EDIT_PRODUCT_DATA:
      {
        return _objectSpread(_objectSpread({}, state), payload);
      }
    case ADD_ADDITIONAL_PRODUCT:
      {
        var otherAdditionalProducts = state.additionalProducts.filter(additionalProduct => additionalProduct.uid !== action.payload.uid);
        return _objectSpread(_objectSpread({}, state), {}, {
          additionalProducts: [...otherAdditionalProducts, payload]
        });
      }
    case REMOVE_ADDITIONAL_PRODUCT:
      {
        var _otherAdditionalProducts = state.additionalProducts.filter(additionalProduct => additionalProduct.uid !== action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          additionalProducts: _otherAdditionalProducts
        });
      }
    case CLEAR_ADDITIONAL_PRODUCTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        additionalProducts: []
      });
    case SET_PROCEED_WITH_INVALID_MOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        canProceedWithInvalidMount: payload
      });
    case CLEAR_PRODUCT:
      return initialState;
    case CLEAR_PRODUCT_PARTIALLY:
      return _objectSpread(_objectSpread({}, initialState), payload);
    case HAS_FILLED_MEASURES_IN_PDP:
      return _objectSpread(_objectSpread({}, state), {}, {
        hasFilledMeasuresInOrderWizard: payload
      });
    default:
      return state;
  }
};