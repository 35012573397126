import styled from "styled-components";
import { breakpoint } from "../../../../utils/breakpoints";
export var BuyNowPopupStyled = styled.div.withConfig({
  displayName: "BuyNowPopupstyled__BuyNowPopupStyled",
  componentId: "sc-8ump89-0"
})(["", ";", "{width:45%;}.buy-now-btn{border-radius:50px;background:transparent;color:#175EB7;border:solid 2px #175EB7;font-size:17px;line-height:1.188rem;font-family:\"HK Nova\",sans-serif;font-weight:700;text-decoration:none;transition:all .3s ease-in-out;padding:.938rem 2rem;display:flex;align-items:center;justify-content:center;text-align:center;height:3.5rem;min-width:264px;&:hover{background:#175EB7;color:#fff;}", "{margin-left:2rem;}", "{margin-left:0;}", "{font-size:1.188rem;}", "{width:100%;min-width:250px;}}"], {
  "display": "flex",
  "flexDirection": "column",
  "@media (min-width: 1280px)": {
    "alignItems": "center",
    "flexDirection": "row",
    "width": "100%",
    "marginBottom": "0px"
  },
  "@media (min-width: 1720px)": {
    "marginBottom": "0px"
  }
}, breakpoint('3xl'), breakpoint('md'), breakpoint('lg'), breakpoint('xl'), breakpoint('3xl'));