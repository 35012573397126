// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mask-root-ay- {\n    background: none;\n    cursor: pointer;\n    display: block;\n    height: 100%;\n    left: 0;\n    opacity: 0;\n    position: fixed;\n    top: 0;\n    transition-duration: 192ms;\n    transition-property: opacity, visibility;\n    transition-timing-function: linear;\n    visibility: hidden;\n    width: 100%;\n    z-index: 2;\n    -webkit-appearance: none;\n}\n\n/* state: active */\n\n.mask-root_active-T-a {\n    opacity: 0.5;\n    transition-duration: 224ms;\n    visibility: visible;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "mask-root-ay-",
	"root_active": "mask-root_active-T-a mask-root-ay-"
};
export default ___CSS_LOADER_EXPORT___;
