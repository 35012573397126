import styled from 'styled-components';
export var StyledAccountTrigger = styled.div.withConfig({
  displayName: "accountTriggerstyled__StyledAccountTrigger",
  componentId: "sc-15hq5i1-0"
})(["align-items:center;display:grid;height:calc(5rem - 8px);display:none;.trigger{", " ", " z-index:1;transition:all .3s ease-in-out;svg{stroke:#fff;}&:hover{svg{stroke:#F5D1CF;}}}}"], {
  "height": "2rem",
  "display": "flex",
  "justifyContent": "center"
}, {
  "paddingLeft": "1.25rem",
  "paddingRight": "0.5rem"
});