import styled from 'styled-components';
import { breakpoint } from '../../../../utils/breakpoints';
export var ButtonStyled = styled.button.attrs(props => ({
  tabIndex: 0
})).withConfig({
  displayName: "buttonstyled__ButtonStyled",
  componentId: "sc-69st4q-0"
})(["border-radius:100px !important;line-height:1.25rem;max-width:100%;min-width:10rem;min-height:2.5rem;outline:none;padding:calc(0.4rem + 1px) 1.5rem calc(0.4rem - 1px);transition-duration:256ms;", " ", " display:flex;align-items:center;justify-content:center;transition:all .3s ease-in-out;&:hover{background:#1459AF;}", "{min-height:3.125rem;font-size:1.125rem;}span{", " text-decoration:none;}.secondary &{", " background-color:transparent;box-shadow:0 0 0 2px #FFD5D7;}}"], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "#175EB7",
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-white) / var(--tw-text-opacity))",
  "textDecoration": "none",
  "display": "inlineFlex",
  "borderRadius": "100px",
  "fontFamily": "HK Nova",
  "padding": "16px 2.5rem",
  "fontWeight": "bold",
  "fontSize": "1rem",
  "margin": "0px",
  "span": {
    "fontFamily": "HK Nova",
    "fontWeight": "bold",
    "fontSize": "1rem"
  }
}, {
  "borderRadius": "100px",
  "backgroundColor": "#175EB7",
  "textDecoration": "none",
  "display": "inlineFlex",
  "fontFamily": "HK Nova",
  "padding": "18px 2rem",
  "fontWeight": "bold",
  "fontSize": "1.125rem",
  "margin": "0",
  "span": {
    "fontFamily": "HK Nova",
    "fontWeight": "bold"
  },
  "lineHeight": "1.75rem",
  "position": "relative",
  "whiteSpace": "nowrap",
  "paddingLeft": "2rem",
  "paddingRight": "2rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
}, breakpoint('lg'), {
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-white) / var(--tw-text-opacity))",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "fontWeight": "500",
  "whiteSpace": "nowrap"
}, {
  "color": "white",
  "fontSize": "0.938rem",
  "textTransform": "none",
  "backgroundColor": "transparent",
  "boxShadow": "inset 0 0 0 2px #FFD5D7",
  "padding": "16px 2.5rem",
  "minHeight": "3.5rem",
  ":focus": {
    "boxShadow": "inset 0 0 0 100px #FFD5D7",
    "color": "#111111"
  },
  ":hover": {
    "boxShadow": "inset 0 0 0 100px #FFD5D7",
    "color": "#111111"
  },
  ":focus span": {
    "color": "#111111"
  },
  ":hover span": {
    "color": "#111111"
  },
  "@media (min-width: 1024px)": {
    "fontSize": "1.25rem",
    "padding": "18px 3.625rem",
    "span": {
      "fontSize": "1.25rem"
    }
  }
});