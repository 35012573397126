// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-root-UvV {\n    background-color: rgb(var(--venia-global-color-background));\n    color: rgb(var(--venia-global-color-text));\n    position: relative;\n    z-index: 1;\n}\n\n.main-root_masked-uAO {\n    position: fixed;\n    width: 100%;\n}\n\n.main-page-mSt {\n    margin: 0 auto;\n    max-width: var(--venia-global-maxWidth);\n    min-height: 40rem;\n    position: relative;\n}\n\n.main-full_width_main_page-f9e {\n    min-width: 100%;\n}\n\n.main-full_width_main_page_customize-DzF {\n    min-width: 100%;\n}\n\n.main-page_masked-HLA {\n}\n\n@media (min-height: 640px) {\n    .main-page-mSt {\n        min-height: calc(100vh - 100px);\n    }\n}\n\n@media (min-height: 768px) {\n    .main-page-mSt {\n        min-height: calc(100vh - 100px);\n    }\n}\n\n@media (max-width: 768px) {\n    .main-page-mSt {\n        min-height: calc(100vh - 100px);\n    }\n}\n\n@media (min-height: 960px) {\n    .main-page-mSt {\n        min-height: calc(100vh - 150px);\n        min-width: 100%;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "main-root-UvV",
	"root_masked": "main-root_masked-uAO main-root-UvV",
	"page": "main-page-mSt",
	"full_width_main_page": "main-full_width_main_page-f9e",
	"full_width_main_page_customize": "main-full_width_main_page_customize-DzF",
	"page_masked": "main-page_masked-HLA main-page-mSt"
};
export default ___CSS_LOADER_EXPORT___;
