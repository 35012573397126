import styled from 'styled-components';
import { breakpoint } from '../../utils/breakpoints';
export var StarRatingItemStyled = styled.div.withConfig({
  displayName: "StarRatingstyled__StarRatingItemStyled",
  componentId: "sc-rhwet6-0"
})(["", ";.ratingAverage{", ";margin-bottom:1.25rem;", "{margin-bottom:2.5rem;}}h3{font-size:1.375rem;line-height:2.5rem;", ";", "{font-size:1.75rem;}}img{max-width:110px;margin:0 auto;", "{max-width:160px;}}p{", ";font-size:1.125rem;line-height:1.875rem;", "{font-size:1.5rem;line-height:2.5rem;}&:last-child{", ";font-size:.938rem;", "{font-size:1.25rem;}}}span{", ";font-size:.938rem;line-height:2.5rem;color:#000;", "{font-size:1.25rem;line-height:2.5rem;}}}"], {
  "textAlign": "center"
}, {
  "display": "flex",
  "alignItems": "center",
  "width": "100%",
  "marginLeft": "auto",
  "marginRight": "auto",
  "justifyContent": "center"
}, breakpoint('lg'), {
  "fontFamily": "HK Nova, sans-serif",
  "fontWeight": "700",
  "marginBottom": "1rem",
  "@media (min-width: 1024px)": {
    "marginBottom": "1.75rem"
  }
}, breakpoint('lg'), breakpoint('lg'), {
  "fontFamily": "Avenir, sans-serif"
}, breakpoint('lg'), {
  "--tw-text-opacity": "1",
  "color": "rgba(245, 209, 207, var(--tw-text-opacity))",
  "fontWeight": "700",
  "@media (min-width: 1024px)": {
    "paddingTop": "1.25rem"
  }
}, breakpoint('lg'), {
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "700"
}, breakpoint('lg'));