import styled from 'styled-components';
export var StyledContactButtons = styled.div.withConfig({
  displayName: "ContactButtonsstyled__StyledContactButtons",
  componentId: "sc-3evo4y-0"
})(["", " > div{", "}ul{", " ", " li{stroke:white;", " &:nth-of-type(1){a{&:before{display:none;font:var(--fa-font-regular);content:'\f58f';}}}&:nth-of-type(2){a,button{&:before{display:none;font:var(--fa-font-regular);content:'\f4ad';}}}&:nth-of-type(3){a{&:before{display:none;font:var(--fa-font-regular);content:'\f0e0';}}}a,button{width:100%;", ";color:#B9B9B9;background:#232323;display:flex;align-items:center;-webkit-tap-highlight-color:transparent;-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;&:hover{svg{color:#EABFBD;}}svg{font-size:1.5rem;margin-right:1rem;color:#175EB7;transition:all .3s ease-in-out;}img{", "}}}}}"], {
  "position": "sticky",
  "top": "0px",
  "zIndex": "10"
}, {
  "margin": "0px"
}, {
  "display": "grid",
  "height": "auto",
  "justifyContent": "center",
  "paddingLeft": "0px",
  "paddingRight": "0px",
  "top": "0px",
  "position": "sticky",
  "width": "100%",
  "zIndex": "10",
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-white) / var(--tw-text-opacity))"
}, props => props.left ? {
  "justifyContent": "flex-start"
} : null, {
  "width": "auto",
  "borderWidth": "0px",
  "textTransform": "none",
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-black) / var(--tw-text-opacity))",
  "listStyleType": "none",
  "marginTop": "0.5rem",
  "marginBottom": "0.5rem"
}, {
  "paddingLeft": "3rem",
  "paddingRight": "3rem",
  "paddingTop": "1rem",
  "paddingBottom": "1rem",
  "textDecoration": "none",
  "display": "flex",
  "alignItems": "center",
  "borderRadius": "9999px",
  "fontWeight": "700"
}, {
  "textDecoration": "none",
  "display": "flex",
  "alignItems": "center",
  "width": "1.5rem",
  "margin": "0px",
  "marginRight": "1.25rem"
});