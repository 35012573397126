import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { getAdvanced, getBackgroundImages, getVerticalAlignment, getIsHidden, getMediaQueries } from '@magento/pagebuilder/lib/utils';
export default ((node, props) => {
  // Determine which node holds the data for the appearance
  var dataNode = props.appearance === 'contained' ? node.childNodes[0] : node;
  var videoOverlayColor = props.appearance === 'full-width' || props.appearance === 'full-bleed' ? node.childNodes[0] && node.childNodes[0].getAttribute('data-video-overlay-color') : dataNode.childNodes[0] && dataNode.childNodes[0].getAttribute('data-video-overlay-color');
  var minHeight = dataNode.style.minHeight ? dataNode.style.minHeight : null;
  var containsDynamicBlock = [...dataNode.childNodes].some(e => {
    return e.getAttribute('data-content-type') === 'dynamic_block';
  });
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
    minHeight: containsDynamicBlock ? null : minHeight
  }, getVerticalAlignment(dataNode)), {}, {
    backgroundColor: dataNode.style.backgroundColor ? dataNode.style.backgroundColor : null
  }, getBackgroundImages(dataNode)), {}, {
    enableParallax: dataNode.getAttribute('data-enable-parallax') === '1',
    parallaxSpeed: parseFloat(dataNode.getAttribute('data-parallax-speed')),
    backgroundType: dataNode.getAttribute('data-background-type'),
    videoSrc: dataNode.getAttribute('data-video-src'),
    videoFallbackSrc: dataNode.getAttribute('data-video-fallback-src'),
    videoLoop: dataNode.getAttribute('data-video-loop') === 'true',
    videoPlayOnlyVisible: dataNode.getAttribute('data-video-play-only-visible') === 'true',
    videoLazyLoading: dataNode.getAttribute('data-video-lazy-load') === 'true',
    videoOverlayColor: videoOverlayColor || null
  }, getAdvanced(dataNode)), getIsHidden(node)), getMediaQueries(dataNode));
});