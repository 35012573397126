import { EVENT_COMMERCE_CART_ADD, EVENT_COMMERCE_CART_REMOVE, EVENT_COMMERCE_CART_VIEW, EVENT_COMMERCE_START_TO_ORDER, EVENT_COMMERCE_CHECKOUT_COMPLETE, EVENT_COMMERCE_CHECKOUT_START, BUTTON_CLICK, CUSTOM_PAGE_VIEW } from './events';
import { capitalizeFirstLetter } from "../common";
import { GTM_LABELS } from "../../common/constant";
var getDataLayer = () => {
  var _window;
  if (typeof ((_window = window) === null || _window === void 0 ? void 0 : _window.dataLayer) === 'undefined') {
    window.dataLayer = [];
  }
  return window.dataLayer;
};
var dataLayerConfig = {
  handle: function (event, data) {
    var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var eventConfig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    var dataLayer = getDataLayer();
    switch (event) {
      case EVENT_COMMERCE_CART_ADD:
        dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'USD',
            items: data
          },
          value: value
        });
        break;
      case EVENT_COMMERCE_CART_REMOVE:
        dataLayer.push({
          event: 'remove_from_cart',
          ecommerce: {
            items: data
          }
        });
        break;
      case EVENT_COMMERCE_CART_VIEW:
        dataLayer.push({
          event: 'view_cart',
          ecommerce: data
        });
        break;
      case EVENT_COMMERCE_START_TO_ORDER:
        dataLayer.push({
          event: 'view_item',
          ecommerce: data
        });
        break;
      case EVENT_COMMERCE_CHECKOUT_START:
        dataLayer.push({
          event: 'begin_checkout',
          ecommerce: data
        });
        break;
      case EVENT_COMMERCE_CHECKOUT_COMPLETE:
        dataLayer.push({
          event: 'purchase',
          ecommerce: data
        });
        break;
      case BUTTON_CLICK:
        {
          var {
            eventIdentifier
          } = eventConfig;
          dataLayer.push({
            event: eventIdentifier,
            event_type: 'button_click',
            event_value: data
          });
          break;
        }
      case CUSTOM_PAGE_VIEW:
        {
          var {
            url
          } = data;
          dataLayer.push({
            event: 'page_view',
            url
          });
          break;
        }
    }
  }
};
var getConfigurableOptionValue = (item, optionLabel) => {
  var {
    configurable_options
  } = item;
  if (!configurable_options) {
    return '';
  }
  var option = configurable_options.find(_ref => {
    var {
      option_label
    } = _ref;
    return option_label === optionLabel;
  });
  if (option === undefined) {
    return '';
  }
  return option.value_label;
};
var getCustomizableOptionValue = (item, optionLabel) => {
  var _option$values$2;
  var {
    customizable_options
  } = item;
  if (!customizable_options) {
    return '';
  }
  var option = customizable_options.find(_ref2 => {
    var {
      label
    } = _ref2;
    return label === optionLabel;
  });
  if (option === undefined) {
    return '';
  }
  if ((option === null || option === void 0 ? void 0 : option.label) === 'Window Name') {
    var _option$values$;
    return option === null || option === void 0 ? void 0 : (_option$values$ = option.values[0]) === null || _option$values$ === void 0 ? void 0 : _option$values$.value;
  }
  return option === null || option === void 0 ? void 0 : (_option$values$2 = option.values[0]) === null || _option$values$2 === void 0 ? void 0 : _option$values$2.label;
};
var mapItemData = item => {
  return {
    item_id: item.product.sku,
    item_name: item.product.name,
    price: item.prices.row_total.value.toFixed(2),
    discount: 0,
    item_category: capitalizeFirstLetter(getConfigurableOptionValue(item, GTM_LABELS.FRAME_COLOR)),
    // Frame color
    item_category2: capitalizeFirstLetter(getConfigurableOptionValue(item, GTM_LABELS.FABRIC_COLOR)),
    // Fabric color
    item_category3: getCustomizableOptionValue(item, GTM_LABELS.CONTROL_TYPE),
    // Control type
    item_category4: getCustomizableOptionValue(item, GTM_LABELS.MOUNT_TYPE),
    // Mount type
    item_category5: getCustomizableOptionValue(item, GTM_LABELS.PREFERRED_ORIENTATION),
    // Orientation
    item_category6: getCustomizableOptionValue(item, GTM_LABELS.WINDOW_TYPE),
    // Window type
    item_category7: getCustomizableOptionValue(item, GTM_LABELS.SELECTED_WIDTH_WHOLE),
    // Width
    item_category8: getCustomizableOptionValue(item, GTM_LABELS.SELECTED_HEIGHT_WHOLE),
    // Height
    item_category9: getCustomizableOptionValue(item, GTM_LABELS.WINDOW_TRIM),
    // Trim
    item_category10: getCustomizableOptionValue(item, GTM_LABELS.WINDOW_TILT),
    // Tilt
    item_category11: getCustomizableOptionValue(item, GTM_LABELS.REMOTE),
    // Remote
    item_category12: getCustomizableOptionValue(item, GTM_LABELS.SELECTED_WIDTH_EIGHTHS),
    // Width eighths
    item_category13: getCustomizableOptionValue(item, GTM_LABELS.SELECTED_HEIGHT_EIGHTHS),
    // Height eighths
    item_category14: getCustomizableOptionValue(item, GTM_LABELS.WINDOW_NAME),
    // Window name
    item_category15: getCustomizableOptionValue(item, GTM_LABELS.INSURANCE),
    // Measure Protect
    quantity: item.quantity,
    item_brand: 'UBlockout'
  };
};
export { dataLayerConfig, mapItemData };