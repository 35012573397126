import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { getBorder, getCssClasses, getIsHidden, getMargin, getPadding, getTextAlign } from '@magento/pagebuilder/lib/utils';
export default (node => {
  var iframe = node.querySelector('iframe');
  var video = node.querySelector('video');
  var wrapper = node.querySelector('[data-element="wrapper"]');
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
    url: iframe && iframe.getAttribute('src') || video && video.getAttribute('src') || null,
    autoplay: !!(video && video.getAttribute('autoplay') === 'true'),
    isPopUp: node.getAttribute('data-pay-popup') === 'true',
    imageSrc: node.getAttribute('data-image-src'),
    title: node.getAttribute('data-title'),
    muted: !!(video && video.getAttribute('muted') === 'true'),
    maxWidth: node.childNodes[0].style.maxWidth || null
  }, getTextAlign(node)), getMargin(node)), getBorder(wrapper)), getPadding(wrapper)), getCssClasses(node)), getIsHidden(node));
});