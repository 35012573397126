// TODO: separate into files constant according sections

var CMS_PAGE_IDENTIFIERS = {
  HOME: 'home',
  PRODUCTS: 'products',
  SUPPORT: 'support'
};
var ROOT_COMPONENT_TYPES = {
  PRODUCT: 'PRODUCT',
  BLOG: 'BLOG'
};
var OPTION_LABEL_CLASS_NAMES = {
  color: 'color',
  frame: 'frame',
  width_wholes: 'width_wholes',
  height_wholes: 'height_wholes',
  width_eighths: 'width_eighths',
  height_eighths: 'height_eighths',
  control_type: 'control_type'
};
var MEASURABLE_PRICE = 'measurablePrice';
var MEASURABLE_SMART_PRICE = 'measurableSmartPrice';
var OPTION_ATTRIBUTE_CODES = {
  COLOR: 'color',
  FRAME: 'frame',
  WIDTH_WHOLES: 'selected-width-whole',
  WIDTH_EIGHTHS: 'selected-width-eighths',
  HEIGHT_WHOLES: 'selected-height-whole',
  HEIGHT_EIGHTHS: 'selected-height-eighths'
};
var CMS_SUPPORT_PAGE_IDENTIFIERS = {
  MEASURING: 'measuring',
  INSTALLATION: 'installation',
  WINDOW_SUITABILITY: 'window-suitability',
  RETURNS: 'returns',
  CONTACT_US: 'contact-us',
  MOTORIZATION: 'motorized-blind',
  SHIPPING: 'shipping-terms',
  FAQ: 'frequently-asked-questions'
};
var CUSTOMIZABLE_OPTION_ATTRIBUTES = {
  WINDOW_TYPE: 'window_type',
  CONTROL_TYPE: 'control_type',
  MOUNT_TYPE: 'mount_type',
  TRIM: 'window-trim',
  TILT: 'window-tilt',
  INSURANCE: 'measure_protect',
  INSTALLATION: 'professional_installation',
  WINDOW_NAME: 'name',
  WIDTH_TOP: 'width-top',
  WIDTH_MIDDLE: 'width-center',
  WIDTH_BOTTOM: 'width-bottom',
  HEIGHT_LEFT: 'height-left',
  HEIGHT_MIDDLE: 'height-center',
  HEIGHT_RIGHT: 'height-right',
  VIEWED_MEASURE_VIDEO: 'viewed-measure-video',
  MOTORIZED_REMOTE_OPTIONS: 'remote',
  WINDOW_NAMES: 'window-name',
  ORIENTATION: 'shade-orientation',
  UUID: 'uuid'
};
var ADDITIONAL_PRODUCT_ATTRIBUTES = {
  ORDER_NUMBER: 'order-number',
  WINDOW_NAME: 'window-name'
};
var CUSTOMIZABLE_OPTION_VALUES = {
  INSIDE: 'inside',
  OUTSIDE: 'outside',
  YES: 'yes',
  NO: 'no',
  UP: 'up',
  DOWN: 'down',
  MANUAL: 'non-motorized',
  MOTORIZED: 'motorized',
  WINDOW_TYPE: {
    BARE: 'bare',
    WINDOW_WITH_SILL: 'sill',
    WINDOW_WITH_TRIM: 'trim',
    WINDOW_WITH_SILL_AND_TRIM: 'trim-and-sill'
  },
  TRIM: {
    SQUARE: 'square',
    OTHER: 'other'
  },
  INSURANCE: {
    YES: 'measure-protected',
    NO: 'no-protection'
  }
};
var CUSTOMIZABLE_OPTION_TYPES = {
  RADIO_OPTION: 'radio_option',
  DROP_DOWN_OPTION: 'drop_down_option'
};
var CUSTOMIZABLE_OPTION_LABELS = {
  INSIDE: 'Inside',
  OUTSIDE: 'Outside',
  YES: 'Yes',
  NO: 'No',
  MANUAL: 'Manual',
  MOTORIZED: 'Motorized',
  WINDOW_TYPE: {
    BARE: 'Bare window',
    WINDOW_WITH_SILL: 'Window + sill',
    WINDOW_WITH_TRIM: 'Window + trim',
    WINDOW_WITH_SILL_AND_TRIM: 'Window + sill & trim'
  },
  TRIM: {
    SQUARE: 'Flat',
    OTHER: 'Other'
  }
};
var EXTENDED_OPTIONS = [{
  attribute_code: OPTION_ATTRIBUTE_CODES.FRAME,
  priority: 1,
  customizedLabel: '',
  isSelectedValueVisible: true,
  selectedValueLabel: 'Frame'
}, {
  attribute_code: OPTION_ATTRIBUTE_CODES.COLOR,
  priority: 2,
  customizedLabel: '',
  isSelectedValueVisible: true,
  selectedValueLabel: 'Fabric'
}, {
  attribute_code: OPTION_ATTRIBUTE_CODES.WIDTH_WHOLES,
  priority: 4,
  customizedLabel: 'Width',
  isSelectedValueVisible: false
}, {
  attribute_code: OPTION_ATTRIBUTE_CODES.WIDTH_EIGHTHS,
  priority: 5,
  customizedLabel: '',
  isSelectedValueVisible: false
}, {
  attribute_code: OPTION_ATTRIBUTE_CODES.HEIGHT_WHOLES,
  priority: 6,
  customizedLabel: 'Height',
  isSelectedValueVisible: false
}, {
  attribute_code: OPTION_ATTRIBUTE_CODES.HEIGHT_EIGHTHS,
  priority: 7,
  customizedLabel: '',
  isSelectedValueVisible: false
}];
var EXTENDED_TREE_ITEMS = [{
  identifier: CMS_SUPPORT_PAGE_IDENTIFIERS.MEASURING,
  priority: 1,
  title: "Measuring"
}, {
  identifier: CMS_SUPPORT_PAGE_IDENTIFIERS.INSTALLATION,
  priority: 2,
  title: "Installation"
}, {
  identifier: CMS_SUPPORT_PAGE_IDENTIFIERS.MOTORIZATION,
  priority: 3,
  title: "Motorization"
}, {
  identifier: CMS_SUPPORT_PAGE_IDENTIFIERS.WINDOW_SUITABILITY,
  priority: 4,
  title: "Window suitability"
}, {
  identifier: CMS_SUPPORT_PAGE_IDENTIFIERS.CONTACT_US,
  priority: 5,
  title: "Contact Us"
}, {
  identifier: CMS_SUPPORT_PAGE_IDENTIFIERS.SHIPPING,
  priority: 6,
  title: "Shipping"
}, {
  identifier: CMS_SUPPORT_PAGE_IDENTIFIERS.RETURNS,
  priority: 7,
  title: "Returns"
}, {
  identifier: CMS_SUPPORT_PAGE_IDENTIFIERS.FAQ,
  priority: 8,
  title: "Frequently asked questions"
}];
var OPTION_TYPES = {
  SWATCH: 'swatch',
  DROPDOWN: 'dropdown'
};

// TODO: should come from backend for related products
var DROP_DOWN_OPTIONS = [{
  value_index: 0,
  label: 0
}, {
  value_index: 1,
  label: 1
}, {
  value_index: 2,
  label: 2
}, {
  value_index: 3,
  label: 3
}, {
  value_index: 4,
  label: 4
}, {
  value_index: 5,
  label: 5
}];
var NONE = 'None';
var WIDTH = 'WIDTH';
var HEIGHT = 'HEIGHT';
var RADIO_BUTTON_VIEW_TYPE = {
  BUTTON: 'BUTTON'
};
var PRODUCT_TYPES = {
  MAIN: 'MAIN',
  UNIT: 'UNIT',
  SMART_HUB: 'SMART_HUB',
  REMOTE: 'REMOTE',
  MOTORIZATION_UPGRADE: 'MOTORIZATION_UPGRADE'
};
var CONTROL_TYPE_EXTRAS_DESCRIPTION = {
  [PRODUCT_TYPES.SMART_HUB]: 'control-type-extra-product-smart-hub-description',
  [PRODUCT_TYPES.REMOTE]: 'control-type-extra-product-remote-description'
};
var ACCORDION_PANEL_CONFIG = {
  COLOR: {
    IDENTIFIER: 'COLOR',
    TITLE: 'Pick your color',
    STEP_NUMBER: 1,
    NEXT_STEP: 'MOUNT_TYPE'
  },
  MOUNT_TYPE: {
    IDENTIFIER: 'MOUNT_TYPE',
    TITLE: 'Choose your style',
    STEP_NUMBER: 2,
    NEXT_STEP: 'MEASURE'
  },
  MEASURE: {
    IDENTIFIER: 'MEASURE',
    TITLE: "Let's get your measurements",
    STEP_NUMBER: 3,
    NEXT_STEP: 'CONTROL_TYPE'
  },
  CONTROL_TYPE: {
    IDENTIFIER: 'CONTROL_TYPE',
    TITLE: 'Choose your control type',
    STEP_NUMBER: 4,
    NEXT_STEP: 'ORIENTATION'
  },
  ORIENTATION: {
    IDENTIFIER: 'ORIENTATION',
    TITLE: 'Preferred shade orientation',
    STEP_NUMBER: 5,
    NEXT_STEP: 'WINDOW'
  },
  WINDOW: {
    IDENTIFIER: 'WINDOW',
    TITLE: 'Name your window',
    STEP_NUMBER: 6,
    NEXT_STEP: null
  }
};
var CUSTOMIZE_CARTID_PARAMETER = 'cartId';
var CUSTOMIZE_ITEMID_PARAMETER = 'itemId';
var CUSTOMIZE_HELP_URL_PARAMETER = 'help';
var PDP_HELP_URL_PARAMETER = 'pdp_help';
var CART_HELP_URL_PARAMETER = 'cart_help';
var CHECKOUT_URL_PARAMETER = 'step';
var PROTECT_PAGE_PARAMETER = 'access';
var CUSTOMIZE_URL_PARAMETER = 'is_ow';
var SHADE_INFO_URL_PARAMETER = 'shade_info';
var UNWANTED_OPTIONS_LABELS_TO_SHOW = ['Window Name', 'Viewed Measure Video', 'Selected Height Whole', 'Selected Width Whole', 'Selected Height Eighths', 'Selected Width Eighths', 'Width Top', 'Width Center', 'Width Bottom', 'Height Left', 'Height Center', 'Height Right', 'UUID'];
var CHANGE_OPTION_LABEL = {
  'Remote Contain': 'Remote',
  'Measure Protect': 'Measure Guarantee'
};
var PRODUCTS_SKU = {
  BLACKOUT_BLIND: 'UB-Shade',
  REMOTE: 'remote',
  SMART_HUB: 'Smart-hub',
  MOTORIZATION_UPGRADE: 'motorization-upgrade'
};
var PRODUCT_TYPE = {
  CONFIGURE_ITEM: 'ConfigurableProduct',
  SIMPLE_ITEM: 'SimpleProduct'
};
var PRODUCT_CART_TYPE = {
  CONFIGURE_ITEM: 'ConfigurableCartItem',
  SIMPLE_ITEM: 'SimpleCartItem'
};

// TODO: remove this after get url from backend in the future
var HOME_PAGE_MOBILE_BANNER_VIDEO = 'https://www.youtube.com/embed/PQc0JY9K5Uk';
var GTM_LABELS = {
  FRAME_COLOR: 'Frame Color',
  FABRIC_COLOR: 'Fabric Color',
  CONTROL_TYPE: 'Control Type',
  SELECTED_WIDTH_WHOLE: 'Selected Width Whole',
  SELECTED_HEIGHT_WHOLE: 'Selected Height Whole',
  WINDOW_TYPE: 'Window Type',
  MOUNT_TYPE: 'Mount Type',
  WINDOW_TRIM: 'Window Trim',
  WINDOW_TILT: 'Window Tilt',
  REMOTE: 'Remote',
  SELECTED_WIDTH_EIGHTHS: 'Selected Width Eighths',
  SELECTED_HEIGHT_EIGHTHS: 'Selected Height Eighths',
  WINDOW_NAME: 'Window Name',
  PREFERRED_ORIENTATION: 'Shade Orientation',
  INSURANCE: 'Measure Protect',
  INSTALLATION: 'Professional Installation'
};
var ACCESSORY_OR_UNIT_PRODUCT_DETAILS_BLOCKS = {
  [PRODUCT_TYPES.UNIT]: 'fabric-replacement-overview-details',
  [PRODUCT_TYPES.REMOTE]: 'remote-overview-details',
  [PRODUCT_TYPES.SMART_HUB]: 'smart-hub-overview-details'
};
var UB_PRODUCT_SOLUTION_TEXT = 'solution';
var UB_PRODUCT_TEXT = 'shade';
var MIN_WIDTH = 8;
var MAX_WIDTH = 98;
var MIN_HEIGHT = 16;
var MAX_HEIGHT = 100;
var SUPPORTED_MOTORIZED_MIN_WIDTH = 22;
var MAX_WIDTH_FOR_OUTSIDE = 96;
var MAX_HEIGHT_FOR_OUTSIDE = 96;
var MEASURE_LIMITATION = {
  MOTORIZED: {
    WIDTH: {
      MIN: SUPPORTED_MOTORIZED_MIN_WIDTH,
      MAX: MAX_WIDTH
    }
  },
  ORIENTATION: {
    WIDTH: {
      MIN: 24,
      MAX: 60
    }
  }
};
var SMART_MEASURE_LIMITATION = {
  MIN_WIDTH: 22,
  MIN_HEIGHT: 16
};
var CUSTOMIZABLE_OPTION_ATTRIBUTES_LABELS = {
  FRAME_COLOR: 'Frame Color',
  FABRIC_COLOR: 'Fabric Color',
  CONTROL_TYPE: 'Control Type',
  SELECTED_WIDTH_WHOLE: 'Selected Width Whole',
  SELECTED_HEIGHT_WHOLE: 'Selected Height Whole',
  WINDOW_TYPE: 'Window Type',
  MOUNT_TYPE: 'Mount Type',
  WINDOW_TRIM: 'Window Trim',
  WINDOW_TILT: 'Window Tilt',
  REMOTE: 'Remote',
  SELECTED_WIDTH_EIGHTHS: 'Selected Width Eighths',
  SELECTED_HEIGHT_EIGHTHS: 'Selected Height Eighths',
  WINDOW_NAME: 'Window Name',
  PREFERRED_ORIENTATION: 'Preferred Shade Orientation',
  INSURANCE: 'Measure Protect',
  INSTALLATION: 'Professional Installation',
  UUID: 'UUID'
};
var MEASURE_VARIANT = {
  WIDTH_TOP: 'Width Top',
  WIDTH_CENTER: 'Width Center',
  WIDTH_BOTTOM: 'Width Bottom',
  HEIGHT_LEFT: 'Height Left',
  HEIGHT_CENTER: 'Height Center',
  HEIGHT_RIGHT: 'Height Right'
};
var NOTIFICATIONS = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFORMATION: 'information'
};
var TRIAL_ACCESS = ['4eb26f801b2245b1106056ea5722eaebc03a8e3d3db148f88e447e3b046d28ab365ecdf210cb84c0a799e428537a4ce8c45d82b9eaa09b3885100c21e0afdda2',
// atomix
'561193277ddf5bb9ea3d15dd54007376c796ddca44a34505db69e5d76c86cc2c7d822861a6551d4bb7b342c9f0edcf64902e576122d433b684d9b6a7b10fd596',
// sH4des!
'36d9583acb6d66f636d4d2f348322176df8d616b65ce3504dc0cd94221edd5593fd492e6b3234994d327dd576e85700ebb03ce3040f69288bf67345fa630ebe9',
// Da4kn5SS#
'6a1fbe65ae301bdec19f0e72e89eb2cbcc41dca40d1af89e7391e3303e1d02bc3d1cfc3334fe069072166bc2b504d5b955ac420c7d6d96ad46681ba90e8fd5e8',
// n0L1ght
'1478bb153c1fed76db645b1e5fdee7f82c2eb06eb1e26077f22201c6dfd62770fd917e7af2575ed2597d3f725963638775f4285ee3312463031920a844143643',
// ubl0ck0utTr1aLs
'23117f2ed12f340f572a90292c49f58f1bfb19e5adda8917b6a2e00e4da1d1b1123375872c5c596296d943438ef98ab94d2cc8d4a5c82ed21bf91486ef54826d',
// M0tOr1z5d
'2c64f5529ea5e1c23af5712ed48bb0354b04bec83f25b926ed576768f595b0fef48e34e50fc2b9cc7f82bb47111ac72dcb4bac397719313dda212b6ad5082962',
// w!nd0w
'42612b4e888ce28c72b37c8ea2ecfaf8aa2981f4a2aac965a49b049cef7d186b3a33ed6c3bb3705fd2e3138b19079d1597cf83692571f09dda821b52a91d906c',
// bL1nd
'aeabbd3e26b8ed58dca93385c1d84742d396056bfc5ce6bdbd5378e99c7d96510b5d2afe5cf8366435eed74c195614f1704732ee9c02138cbf10c4a0d8e75e7f',
// sl55pIng
'd08f5f1fab5e56a83222da41356cae38218a3f84c14584822015bcf9c19db69e11231e5ceb273610a87fb862a285925848bb614971cf4f2d9d56f5257a149a87',
// smA5t_hub
'414d9b61b92e995b050da668a03f94bd18d1df1eb7633bb0f44399c9b2579e4f0ad782814dadecf5fe1cc329925c210ea29f455e2170a88d8d7d5ed4fa2423d0',
// r#m0te15
'5abaac59c4194f429b37604f1e6d0bcf4aceaf67adb938659d86f8d8bcc723dcd1a268505144bf5b2b13b861979b281624fbf0167bedce02d4580da904999aa8',
// Contr0l
'9c40eff30282d023e949e43b9e6b0546ec524d3fd68b32cb4c42e913f7d8c96b77cbddefad7ac8f29fe0c5093487f47ad849ecdac7b116924e32e32ec4ca4032',
// Bl4ck0ut
'a48e6da9c6245f8e9d775265655a9a7148462bff360d3f166094afeb0eb752fe829d5c09708a0be114aa6e2ccd821790023a955499cf99e6f26d08e95bb6a76e',
// w1nt34Wh!te
'55668d9906e565884dc5f3a1716644d0a2510f97ce9ac6f805f077a9315988fb8a9a76bd55b7b11e5f1b83070f1e50ea036b876f18131661a162770b2a2d4671',
// d4rkn5$$
'278400dad8a744fa9b43ce7fc5553702d698f36d37482986b24ab22ef4fa735e7481b4fdb429cd4afbe835f5955302f8c3b8375542085f6543834f46a848625c' // s133pBetter
];

var YOTPO_SETTINGS = {
  GENERAL: {
    SCRIPT_URL: '//staticw2.yotpo.com/B1F9T0AgylOrXql9wgIznJJpEhIzy16mEgu7zzIM/widget.js',
    PRODUCT_ID: '1222',
    PRODUCT_SKU: 'UB-Shade',
    NAME: 'Ultimate Blackout Shade',
    DESCRIPTION: 'Experience our uniquely designed & patented complete blackout shade solution, customized specific to your requirements.',
    DOMAIN: 'https://www.ublockout.com/',
    PRICE: {
      MIN: '296.32',
      MAX: '1000.0',
      CURRENCY_CODE: 'USD'
    },
    URL: '/ultimate-blackout-shade.html',
    IMAGE_URL: '/media/catalog/product/u/b/ub-w-w_1.jpg',
    SITE_ID: 'yotpo_site_reviews'
  },
  UGC: {
    GALLERY_ID: '6438b95b6ff9340026015083'
  },
  STARS: {
    APP_KEY: 'B1F9T0AgylOrXql9wgIznJJpEhIzy16mEgu7zzIM'
  }
};
var COLORS_TO_HIDE = {
  FABRIC: ['Light Filtering White']
};
var PAYMENT_STATUS = {
  Failed: "Failed",
  Succeeded: "Succeeded"
};
var MINIMUM_SHADE_PRICE = 196.49;
var COMPARE_PRODUCTS = {
  ULTIMATE: 'ultimate',
  SMART: 'smart'
};
var AFFILIATE_URL_PARAMETERS = {
  DEPRECATED: {
    AFFILIATE: 'affiliate'
  },
  CURRENT: 'special'
};
var CART_INFO_TEXT = {
  ESTIMATED_DELIVERY_TEXT: 'Shipped in 3 weeks'
};
var UBLOCKOUT_SHADE = 'UBlockout Shade';
var EXTERNAL_LINKS = {
  HELP_CENTER: {
    INSTALLATION: 'https://get.ublockout.com/installation',
    WINDOW_SUITABILITY: 'https://get.ublockout.com/window-suitability'
  },
  BOOK_FREE_CONSULTATION: 'https://meeting.ublockout.com/meetings/cristian140/consultation-'
};
export { CMS_PAGE_IDENTIFIERS, OPTION_LABEL_CLASS_NAMES, OPTION_ATTRIBUTE_CODES, EXTENDED_OPTIONS, ROOT_COMPONENT_TYPES, OPTION_TYPES, CUSTOMIZABLE_OPTION_ATTRIBUTES, CUSTOMIZABLE_OPTION_VALUES, CUSTOMIZABLE_OPTION_LABELS, CUSTOMIZABLE_OPTION_TYPES, DROP_DOWN_OPTIONS, NONE, WIDTH, HEIGHT, RADIO_BUTTON_VIEW_TYPE, PRODUCT_TYPES, ADDITIONAL_PRODUCT_ATTRIBUTES, ACCESSORY_OR_UNIT_PRODUCT_DETAILS_BLOCKS, CONTROL_TYPE_EXTRAS_DESCRIPTION, CUSTOMIZE_HELP_URL_PARAMETER, PDP_HELP_URL_PARAMETER, CART_HELP_URL_PARAMETER, CHECKOUT_URL_PARAMETER, PROTECT_PAGE_PARAMETER, ACCORDION_PANEL_CONFIG, UNWANTED_OPTIONS_LABELS_TO_SHOW, CHANGE_OPTION_LABEL, PRODUCTS_SKU, HOME_PAGE_MOBILE_BANNER_VIDEO, GTM_LABELS, PRODUCT_CART_TYPE, UB_PRODUCT_TEXT, UB_PRODUCT_SOLUTION_TEXT, CUSTOMIZABLE_OPTION_ATTRIBUTES_LABELS, MEASURE_VARIANT, CUSTOMIZE_CARTID_PARAMETER, CUSTOMIZE_ITEMID_PARAMETER, MEASURE_LIMITATION, TRIAL_ACCESS, PRODUCT_TYPE, CMS_SUPPORT_PAGE_IDENTIFIERS, EXTENDED_TREE_ITEMS, YOTPO_SETTINGS, COLORS_TO_HIDE, PAYMENT_STATUS, MEASURABLE_PRICE, MEASURABLE_SMART_PRICE, MINIMUM_SHADE_PRICE, AFFILIATE_URL_PARAMETERS, COMPARE_PRODUCTS, SMART_MEASURE_LIMITATION, MIN_WIDTH, MIN_HEIGHT, MAX_HEIGHT, MAX_WIDTH, SUPPORTED_MOTORIZED_MIN_WIDTH, MAX_WIDTH_FOR_OUTSIDE, MAX_HEIGHT_FOR_OUTSIDE, CUSTOMIZE_URL_PARAMETER, SHADE_INFO_URL_PARAMETER, CART_INFO_TEXT, NOTIFICATIONS, UBLOCKOUT_SHADE, EXTERNAL_LINKS };