import { useState, useEffect, useMemo } from 'react';
export function useMediaQuery(query) {
  var [matches, setMatches] = useState(false);
  var media = useMemo(() => {
    return window.matchMedia(query);
  }, [query]);
  useEffect(() => {
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    var listener = () => {
      setMatches(media.matches);
    };
    try {
      media.addEventListener('change', listener);
    } catch (_unused) {
      try {
        // Additional compatibility to cover all browsers
        media.addListener(listener);
      } catch (error) {
        console.error(error);
      }
    }
  }, [matches, query]);
  return matches;
}

/**
 * Gets the pixel value for the given breakpoint key. The keys and values here
 * should correspond to the ones defiend in the variables CSS file.
 *
 * @param key the key, for example 'md' for medium or 'sx' for extra-small
 * @returns the corresponding value in pixels, as a number
 */
export function getBreakpoint(key) {
  switch (key) {
    case 'xs':
      return '425px';
    case 'sm':
      return '640px';
    case 'md':
      return '768px';
    case 'lg':
      return '1024px';
    case 'xl':
      return '1280px';
    case '2xl':
      return '1536px';
    case '3xl':
      return '1720px';
    case '4xl':
      return '2560px';
    case '5xl':
      return '3840px';
    default:
      throw new Error('Unknown breakpoint');
  }
}