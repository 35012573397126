import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoint } from '../../utils/breakpoints';
export var RadioButtonGroupStyled = styled.div.withConfig({
  displayName: "RadioButtonGroupstyled__RadioButtonGroupStyled",
  componentId: "sc-ob2sbo-0"
})(["", ";@media (min-width:540px){", ";}@media (min-width:1100px){", ";}.mount-type-only{display:none;}}"], {
  "display": "flex",
  "gap": "2rem",
  "flexWrap": "wrap",
  "justifyContent": "space-around",
  "@media (min-width: 1024px)": {
    "gap": "1.5rem"
  }
}, {
  "justifyContent": "flex-start"
}, {
  "gap": "2rem"
});
var fade = keyframes(["from{opacity:1;}to{opacity:0;}"]);
var beforeAnim = keyframes(["from{opacity:1;width:0;transform:translateX(0px) rotate(45deg);transform-origin:left bottom;}to{opacity:1;width:50%;transform:translateX(0px) rotate(45deg);transform-origin:left bottom;}"]);
var afterAnim = keyframes(["from{opacity:1;width:0;transform:translateX(calc(50 / 8 * 1px)) rotate(-45deg);transform-origin:left bottom;}to{opacity:1;width:100%;transform:translateX(calc(50 / 8 * 1px)) rotate(-45deg);transform-origin:left bottom;}"]);
export var RadioButtonImageStyled = styled.div.withConfig({
  displayName: "RadioButtonGroupstyled__RadioButtonImageStyled",
  componentId: "sc-ob2sbo-1"
})(["width:132px;height:132px;position:relative;@media (max-width:385px){width:124px;height:124px;}", "{width:115px;height:115px;}", "{width:120px;height:120px;}@media (min-width:1160px){width:130px;height:130px;}", "{width:150px;height:150px;}.checkmark{position:absolute;top:50%;left:50%;width:calc(50 * 1px);height:calc(50 * 1px);cursor:pointer;transform:translate(-25px,-25px);&:before{position:absolute;left:0;bottom:calc(50 / 2.5 * 1px);height:calc(6 * 1px);width:0;background-color:#0B865D;content:\"\";border-radius:10px;}&:after{position:absolute;left:calc(50 / 4.3 * 1px);bottom:calc(50 / 23 * 1px);height:calc(6 * 1px);width:0;background-color:#0B865D;content:\"\";border-radius:10px;}}", ";"], breakpoint('sm'), breakpoint('lg'), breakpoint('xl'), props => props.disabled ? {
  "opacity": "0.7",
  "cursor": "not-allowed"
} : {});
export var RadioButtonStyled = styled.label.withConfig({
  displayName: "RadioButtonGroupstyled__RadioButtonStyled",
  componentId: "sc-ob2sbo-2"
})(["", ";input{", ";&:focus{+ div.radio-content{.radio-image{padding:0;", "}}}&:checked{+ div.radio-content{.radio-span{&:before{content:'';", ";}}.radio-image{padding:0;", " .checkmark{&:before{animation-name:", ",", ";animation-duration:.3s,.3s;animation-delay:0s,2s;animation-timing-function:ease-in-out;animation-fill-mode:forwards;}&:after{animation-name:", ",", ";animation-duration:.3s,.3s;animation-fill-mode:forwards;animation-delay:.3s,2s;animation-timing-function:ease-in-out;}}}}}}.radio-content{display:flex;flex-direction:column;align-items:center;&.disabled{opacity:0.4;cursor:not-allowed;pointer-events:none;}.label-txt{", ";font-size:.813rem;line-height:1.875rem;", "{font-size:1.125rem;line-height:1.875rem;}}}span{", ";&.radio-span{", ";width:fit-content;min-width:40px;min-height:40px;&:before{content:'';", ";}}&.radio-image{", " border-color:#cecece;background:#fff;border-width:1px;transition:all 0.1s ease-in-out;padding:0;width:140px;height:140px;display:flex;justify-content:center;align-items:center;@media (max-width:385px){width:132px;height:132px;}", "{width:123px;height:123px;}@media (min-width:1160px){width:138px;height:138px;}", "{width:158px;height:158px;}&:hover{padding:0;", "}}img,div{margin:0 !important;}img{border-radius:50%;}}.button{background-color:transparent;color:#175EB7;border-radius:100px;border:2px solid #175EB7;padding:1.125rem 2rem;cursor:pointer;&.disabled{opacity:0.7;cursor:not-allowed;color:#FFFFFF;background-color:#175EB7;}}}"], {
  "textAlign": "center"
}, {
  "position": "absolute",
  "opacity": "0"
}, {
  "borderWidth": "4px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(11, 134, 93, var(--tw-border-opacity))"
}, {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(23, 94, 183, var(--tw-bg-opacity))"
}, {
  "borderWidth": "4px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(11, 134, 93, var(--tw-border-opacity))"
}, beforeAnim, fade, afterAnim, fade, {
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "700"
}, breakpoint('md'), {
  "borderRadius": "9999px",
  "overflow": "hidden",
  "display": "flex",
  "cursor": "pointer",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(219, 219, 219, var(--tw-border-opacity))"
}, {
  "padding": "0.5rem",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(12, 57, 129, var(--tw-border-opacity))",
  "marginLeft": "auto",
  "marginRight": "auto"
}, {
  "backgroundImage": "none",
  "width": "100%",
  "display": "block",
  "borderRadius": "9999px"
}, {
  "borderWidth": "4px",
  "marginBottom": "0.75rem"
}, breakpoint('sm'), breakpoint('xl'), {
  "borderWidth": "4px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(11, 134, 93, var(--tw-border-opacity))"
});
export var TickIcon = styled(FontAwesomeIcon).withConfig({
  displayName: "RadioButtonGroupstyled__TickIcon",
  componentId: "sc-ob2sbo-3"
})(["", ";"], {
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
  "marginLeft": "1rem"
});