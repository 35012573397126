// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".message-root-PRA {\n    color: rgb(var(--venia-global-color-text));\n    font-size: 0.875rem;\n    font-weight: 400;\n    line-height: 1rem;\n    padding: 0.625rem 0.125rem 0.125rem;\n}\n\n.message-root-PRA:empty {\n    display: none;\n}\n\n.message-root_error-UMR {\n    font-family: 'Avenir', \"sans-serif\";\n    color: rgb(var(--venia-global-color-error));\n    font-weight: var(--venia-global-fontWeight-semibold);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "message-root-PRA",
	"root_error": "message-root_error-UMR message-root-PRA"
};
export default ___CSS_LOADER_EXPORT___;
