import styled from 'styled-components';
export var FooterMenuDesktopStyled = styled.div.withConfig({
  displayName: "FooterMenustyled__FooterMenuDesktopStyled",
  componentId: "sc-1lhmtmj-0"
})(["", " [class*='cmsBlock-root']{", "}[class*='cmsBlock-content-']{", " > div{", "}}ul{", " li{", " ", " a{text-decoration:none;}}}"], {
  "display": "none",
  "width": "100%",
  "@media (min-width: 768px)": {
    "display": "flex"
  }
}, {
  "marginTop": "0px",
  "marginLeft": "0px",
  "display": "flex",
  "width": "100%"
}, {
  "width": "100%",
  "@media (min-width: 768px)": {
    "display": "flex"
  },
  "@media (min-width: 1024px)": {
    "flexDirection": "column"
  }
}, {
  "width": "50%",
  "@media (min-width: 768px)": {
    "display": "flex"
  },
  "@media (min-width: 1024px)": {
    "width": "100%"
  }
}, {
  "listStyleType": "none",
  "padding": "0px",
  "marginTop": "0px"
}, {
  "padding": "0px",
  "marginTop": "0.25rem",
  "marginBottom": "0.25rem",
  "display": "block"
}, {
  "fontWeight": "400",
  "display": "block",
  "fontSize": "1.125rem",
  "lineHeight": "1.75rem",
  "--tw-text-opacity": "1",
  "color": "rgba(102, 102, 102, var(--tw-text-opacity))"
});
export var FooterMenuMobileStyled = styled.div.withConfig({
  displayName: "FooterMenustyled__FooterMenuMobileStyled",
  componentId: "sc-1lhmtmj-1"
})(["", " [class*='cmsBlock-root']{", " margin-right:0 !important;}[class*='accordion-root']{", " border-width:1px;", "}[class*='section-root']{", " border-bottom-width:1px;}[class*='section-contents']{padding:0;}button{", " > span{", "}[class*='icon-root']{", " svg{stroke:#175EB7;}}}ul{", ";li{", ";", ";color:#B9B9B9;font-size:1rem;line-height:2rem;}}"], {
  "display": "block",
  "@media (min-width: 768px)": {
    "display": "none"
  }
}, {
  "marginTop": "0px",
  "marginLeft": "0px"
}, {
  "marginTop": "3rem",
  "marginLeft": "0px"
}, {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(102, 102, 102, var(--tw-border-opacity))",
  "borderRightWidth": "0px",
  "borderLeftWidth": "0px",
  "borderRadius": "0px"
}, {
  "marginTop": "0px",
  "marginLeft": "0px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(102, 102, 102, var(--tw-border-opacity))"
}, {
  "position": "relative"
}, {
  "paddingLeft": "0px",
  "paddingRight": "0px",
  "height": "3.5rem",
  "fontWeight": "500"
}, {
  "position": "absolute",
  "height": "100%",
  "width": "100%",
  "justifyContent": "flex-end"
}, {
  "listStyleType": "none",
  "padding": "0px",
  "marginTop": "0px"
}, {
  "padding": "0px",
  "marginTop": "0.25rem",
  "marginBottom": "0.25rem",
  "display": "block"
}, {
  "fontWeight": "400",
  "display": "block",
  "fontSize": "1.125rem",
  "lineHeight": "1.75rem",
  "--tw-text-opacity": "1",
  "color": "rgba(102, 102, 102, var(--tw-text-opacity))"
});