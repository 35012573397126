import styled from 'styled-components';
export var DeliveryIconsStyled = styled.div.withConfig({
  displayName: "DeliveryIconsstyled__DeliveryIconsStyled",
  componentId: "sc-skbx4v-0"
})(["display:flex;justify-content:flex-start;align-items:flex-start;flex-direction:column;img{flex:0 0 auto;box-sizing:border-box;border-radius:1px;margin-top:4px;}ul{display:flex;width:100%;gap:1rem;}p{", "}"], {
  "fontFamily": "Avenir, sans-serif",
  "marginLeft": ".75rem",
  "--tw-text-opacity": "1",
  "color": "rgba(176, 176, 176, var(--tw-text-opacity))",
  "fontSize": "1rem",
  "fontWeight": "500",
  "@media (min-width: 1536px)": {
    "fontSize": ".85rem"
  }
});