import { CUSTOMIZABLE_OPTION_ATTRIBUTES, OPTION_ATTRIBUTE_CODES } from "../../common/constant";
export var EVENT_COMMERCE_START_TO_ORDER = 'commerceStartToOrder';
export var EVENT_COMMERCE_CART_ADD = 'commerceAddToCart';
export var EVENT_COMMERCE_CART_REMOVE = 'commerceCartRemove';
export var EVENT_COMMERCE_CART_VIEW = 'commerceCartView';
export var EVENT_COMMERCE_CHECKOUT_START = 'commerceCheckoutStart';
export var EVENT_COMMERCE_CHECKOUT_COMPLETE = 'commerceCheckoutComplete';
export var BUTTON_CLICK = 'BUTTON_CLICK';
export var CUSTOM_PAGE_VIEW = 'CUSTOM_PAGE_VIEW';

// Order wizard step tracking events
export var EVENTS = {
  BUTTONS: {
    BLACKOUT: {
      CUSTOMIZE_AND_BUY: 'customize_and_buy_button',
      BUY_NOW_MEASURE_LATER: 'buy_now_measure_later_button'
    }
  },
  SHADE: {
    COLORS: {
      [OPTION_ATTRIBUTE_CODES.FRAME]: 'pdp_frame_color',
      [OPTION_ATTRIBUTE_CODES.COLOR]: 'pdp_fabric_color'
    },
    MEASURE: 'pdp_measures',
    MOTORIZED: 'pdp_motorized'
  },
  ORDER_WIZARD: {
    STEP_1: {
      [OPTION_ATTRIBUTE_CODES.FRAME]: 'order_wizard_frame_color',
      [OPTION_ATTRIBUTE_CODES.COLOR]: 'order_wizard_fabric_color'
    },
    STEP_2: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.MOUNT_TYPE]: 'order_wizard_mount_type',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.WINDOW_TYPE]: 'order_wizard_window_type',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.TRIM]: 'order_wizard_trim'
    },
    STEP_3: {
      MEASURES: 'order_wizard_measures',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.INSURANCE]: 'order_wizard_measure_guarantee'
    },
    STEP_4: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.CONTROL_TYPE]: 'order_wizard_control_type',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.MOTORIZED_REMOTE_OPTIONS]: 'order_wizard_remote'
    },
    STEP_5: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.ORIENTATION]: 'order_wizard_orientation'
    },
    STEP_6: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.WINDOW_NAMES]: 'order_wizard_window_name'
    }
  }
};
export var ATTRIBUTES = {
  SHADE: {
    COLORS: {
      [OPTION_ATTRIBUTE_CODES.FRAME]: 'frame_color',
      [OPTION_ATTRIBUTE_CODES.COLOR]: 'fabric_color'
    },
    MEASURE: 'measures',
    MOTORIZED: 'motorized'
  },
  ORDER_WIZARD: {
    STEP_1: {
      [OPTION_ATTRIBUTE_CODES.FRAME]: 'frame_color',
      [OPTION_ATTRIBUTE_CODES.COLOR]: 'fabric_color'
    },
    STEP_2: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.MOUNT_TYPE]: 'mount_type',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.WINDOW_TYPE]: 'window_type',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.TRIM]: 'trim'
    },
    STEP_3: {
      MEASURES: 'measures',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.INSURANCE]: 'measure_guarantee'
    },
    STEP_4: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.CONTROL_TYPE]: 'control_type',
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.MOTORIZED_REMOTE_OPTIONS]: 'remote'
    },
    STEP_5: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.ORIENTATION]: 'orientation'
    },
    STEP_6: {
      [CUSTOMIZABLE_OPTION_ATTRIBUTES.WINDOW_NAMES]: 'window_name'
    }
  }
};