import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { getBorder, getCssClasses, getIsHidden, getMargin, getPadding, getTextAlign } from '@magento/pagebuilder/lib/utils';
export default (node => {
  if (!node.childNodes[0]) {
    return {};
  }
  var props = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
    sections: []
  }, getPadding(node)), getMargin(node)), getBorder(node)), getCssClasses(node)), getTextAlign(node)), getIsHidden(node));

  // Add each accordion-item into props.section
  for (var section of node.childNodes[0].children) {
    props.sections.push({
      id: section.getAttribute('id'),
      isOpen: section.getAttribute('data-open-on-load') === 'true',
      title: section.childNodes[0].childNodes[0].childNodes[0].textContent,
      content: section.childNodes[1].innerHTML,
      styles: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, getPadding(section)), getMargin(section)), getBorder(section)), getCssClasses(section)), getTextAlign(section)), getIsHidden(section))
    });
  }
  return props;
});