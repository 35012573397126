export var productFeaturesData = [{
  title: "Effortless DIY innovation",
  description: "Use our personalized step-by-step video instructions for a hassle-free installation.",
  mediaType: 'image',
  mediaUrl: "../assets/pdp-features/easy-to-set-up1.5x.jpg"
}, {
  title: "Unlock energy savings",
  description: "Stay comfortably cool during scorching summers and cozy all winter long, all while enhancing your home’s energy efficiency.",
  mediaType: 'image',
  mediaUrl: "../assets/pdp-features/energy-savings1.5x.jpg"
}, {
  title: "Privacy-focused solution",
  description: "Choose the orientation that suits you best! Opt for the privacy-focused bottom-to-top configuration or the classic top-to-bottom.",
  mediaType: 'vimeo',
  // mediaUrl: "../assets/pdp-features/privacy-focused1.5x.jpg",
  mediaUrl: "870955252"
}, {
  title: "Childproof innovation",
  description: "Thoughtfully engineered with little hands in mind, we eliminate exposed cords for their protection.",
  mediaType: 'image',
  mediaUrl: "../assets/pdp-features/child-safe1.5x.jpg"
}, {
  title: "Upgrade any time",
  description: "Easily transform your manual Ultimate Blackout Solution into a motorized smart solution whenever it suits you.",
  mediaType: 'image',
  mediaUrl: "../assets/pdp-features/upgradable1.5x.jpg"
}, {
  title: "Hassle-free charging",
  description: "Charging your motorized shade is easy, simply plug it in. No heavy lifting or electrician required.",
  mediaType: 'vimeo',
  // mediaUrl: "../assets/pdp-features/easy-to-charge1.5x.jpg",
  mediaUrl: '870952777'
}, {
  title: "Easy maintenance",
  description: "With just a gentle wipe down using a damp cloth, keeping your shade and frame pristine is simple.",
  mediaType: 'image',
  mediaUrl: "../assets/pdp-features/interchangeable-fabric1.5x.jpg"
}, {
  title: "Built to last",
  description: "Our high-quality products are built for the long haul. The durable fabric and lightweight aluminum frame combine for enduring excellence.",
  mediaType: 'image',
  mediaUrl: "../assets/pdp-features/built-to-last1.5x.jpg"
}, {
  title: "Precision engineering",
  description: "Our patented design and high-quality engineering combine to create shades that exceed industry standards.",
  mediaType: 'image',
  mediaUrl: "../assets/pdp-features/precision-engineering1.5x.jpg"
}];