import styled from 'styled-components';
import { breakpoint } from '../../../../utils/breakpoints';
export var VideoStyled = styled.div.withConfig({
  displayName: "videostyled__VideoStyled",
  componentId: "sc-1uio5ml-0"
})(["line-height:0;position:relative;.inner{display:inline-block;width:100%;}.wrapper{transform:translateZ(0px);overflow:hidden;}.container{padding-top:45.25%;position:relative;overflow:hidden;}.video{position:absolute;height:100%;width:100%;left:0;top:0;}.placeholder-image{background-position:center;background-size:cover;padding-bottom:60%;border-radius:12px;position:relative;overflow:hidden;display:flex;div{transform:translate(-50%,-50%);border-radius:50%;position:absolute;display:flex;height:80px;width:80px;left:50%;top:50%;}img{position:absolute;visibility:hidden;}button{", ";font-size:0;&:after{content:url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 58 65.7' style='enable-background:new 0 0 58 65.7' xml:space='preserve'%3E%3Cpath d='M55.5 28.5c2.4 1.4 3.2 4.4 1.8 6.8-.4.8-1.1 1.4-1.8 1.8L7.5 65c-2.4 1.4-5.4.6-6.8-1.8-.5-.8-.7-1.7-.7-2.6V5c0-2.8 2.2-5 5-5 .9 0 1.7.2 2.5.7l48 27.8z' style='fill:%23fff'/%3E%3C/svg%3E\");transform:translate(50%,-50%);position:absolute;display:block;height:auto;width:21px;right:50%;top:50%;", "{width:60px;}}}}&:not(.home-page-video){.placeholder-image{div{width:100%;height:100%;&:after{content:url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 451.827 451.827' style='enable-background:new 0 0 451.827 451.827' xml:space='preserve'%3E%3Cpath style='fill:%23175EB7' d='M225.922 0C101.351 0 .004 101.347.004 225.917s101.347 225.909 225.917 225.909c124.554 0 225.901-101.347 225.901-225.909C451.823 101.347 350.476 0 225.922 0z'/%3E%3C/svg%3E\");transform:translate(50%,-50%);position:absolute;display:block;height:auto;width:15%;right:50%;top:50%;}}button{&:after{width:4%;}}}}}"], {
  "position": "absolute",
  "width": "100%",
  "height": "100%"
}, breakpoint('lg'));
export var VideoModalStyled = styled.div.withConfig({
  displayName: "videostyled__VideoModalStyled",
  componentId: "sc-1uio5ml-1"
})(["", ";background-color:#000000c9;height:100vh;z-index:1;"], {
  "position": "fixed",
  "width": "100%",
  "height": "100%",
  "display": "flex",
  "justifyContent": "center",
  "alignItems": "center",
  "top": "0px",
  "left": "0px"
});
export var CloseButtonStyled = styled.button.withConfig({
  displayName: "videostyled__CloseButtonStyled",
  componentId: "sc-1uio5ml-2"
})(["position:fixed;z-index:2;color:black;background:white;right:1.25rem;top:1.25rem;display:flex;justify-content:center;align-items:center;padding:10px;border-radius:50%;width:30px;height:30px;", " font-size:0;", "{font-size:.75rem;line-height:1rem;width:70px;height:70px;right:1.875rem;top:1.875rem;}", "{width:90px;height:90px;font-size:1rem;line-height:2rem;}svg{stroke:#000;", "{margin-left:5px;}}"], {
  "fontFamily": "HK Nova, sans-serif",
  "fontWeight": "500"
}, breakpoint('md'), breakpoint('lg'), breakpoint('md'));
export var VideoModalContainerStyled = styled.div.withConfig({
  displayName: "videostyled__VideoModalContainerStyled",
  componentId: "sc-1uio5ml-3"
})(["line-height:0;position:relative;z-index:99999;.container{padding-top:45.25%;position:relative;overflow:hidden;}.video{position:absolute;height:100%;width:100%;left:0;top:0;}"]);