export var navigationLinks = {
  products: [{
    title: "Ultimate Blackout Shade",
    url: "/ultimate-blackout-shade.html"
  }, {
    title: "Remote",
    url: "/remote.html"
  }, {
    title: "Motorization",
    url: "/motorization-upgrade.html"
  }, {
    title: "SmartHub",
    url: "/smart-hub.html"
  }],
  solutions: [{
    title: "Bedrooms",
    url: "https://get.ublockout.com/solutions/bedrooms"
  }, {
    title: "Nursery and Kids Rooms",
    url: "https://get.ublockout.com/solutions/nursery-and-kids-rooms"
  }, {
    title: "Home Theaters",
    url: "https://get.ublockout.com/solutions/home-theater-and-entertainment"
  }, {
    title: "Hotels",
    url: "https://get.ublockout.com/solutions/hotels"
  }],
  howItWorks: [{
    title: "Window Suitability",
    url: "https://get.ublockout.com/window-suitability"
  }, {
    title: "Measuring",
    url: "/help-center/measuring"
  }, {
    title: "Installation",
    url: "https://get.ublockout.com/installation"
  }]
};