import styled from 'styled-components';
export var PageLoadingIndicatorStyled = styled.div.withConfig({
  displayName: "pageLoadingIndicatorstyled__PageLoadingIndicatorStyled",
  componentId: "sc-1uxhpa3-0"
})(["height:2px;", " > div{height:100%;transform-origin:left;transition:transform 0.25s linear;transform:translateX(-100%);", "}.indicator_off{opacity:0;transform:translateX(-100%);}.indicator_loading{opacity:1;transform:translateX(-25%);}.indicator_done{opacity:1;transform:translateX(0%);}"], {
  "overflow": "hidden",
  "width": "100%",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(var(--color-black) / var(--tw-bg-opacity))",
  "position": "absolute",
  "top": "0px",
  "left": "0px"
}, {
  "width": "100%",
  "position": "absolute",
  "top": "0px",
  "left": "0px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(var(--color-gray-800) / var(--tw-bg-opacity))",
  "opacity": "0"
});