import styled from 'styled-components';
export var UbManufacturedStyled = styled.div.withConfig({
  displayName: "UbManufacturedstyled__UbManufacturedStyled",
  componentId: "sc-whgez1-0"
})(["display:flex;justify-content:flex-start;align-items:flex-start;img{flex:0 0 auto;box-sizing:border-box;border-radius:1px;margin-top:4px;}p{", "}"], {
  "fontFamily": "Avenir, sans-serif",
  "marginLeft": ".75rem",
  "--tw-text-opacity": "1",
  "color": "rgba(176, 176, 176, var(--tw-text-opacity))",
  "fontSize": "1rem",
  "fontWeight": "500",
  "@media (min-width: 1536px)": {
    "fontSize": ".85rem"
  }
});