// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../node_modules/@magento/venia-ui/lib/components/clickable.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cartTrigger-counter--u5 {\n    top: 14px;\n    position: absolute;\n    left: 2px;\n    font-weight: 600;\n    box-sizing: border-box;\n    padding: 0 0.25rem;\n    background-color: #175EB7;\n    border-radius: 100px;\n    color: rgb(var(--venia-global-color-background));\n    line-height: 1.25rem;\n    min-width: 1.25rem;\n    font-size: 0.8125rem;\n}\n\n.cartTrigger-trigger-xpY {\n    align-content: center;\n    display: flex;\n    height: 3rem;\n    justify-content: center;\n    min-width: 3rem;\n    position: relative;\n    z-index: 1;\n}\n\n.cartTrigger-triggerContainer-Yne {\n    align-items: center;\n    display: grid;\n    /* The full height of the site header minus (box shadow height * 2). */\n    height: calc(5rem - 8px);\n}\n\n.cartTrigger-triggerContainer_open-RLS {\n    box-shadow: 0 4px #175EB7;\n}\n\n.cartTrigger-link-uhZ {\n    display: none;\n}\n\n/*\n * Mobile-specific styles.\n */\n@media (max-width: 960px) {\n    .cartTrigger-triggerContainer-Yne {\n        display: none;\n    }\n\n    .cartTrigger-link-uhZ {\n        display: flex;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counter": "cartTrigger-counter--u5",
	"trigger": "cartTrigger-trigger-xpY " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + "",
	"triggerContainer": "cartTrigger-triggerContainer-Yne",
	"triggerContainer_open": "cartTrigger-triggerContainer_open-RLS cartTrigger-triggerContainer-Yne",
	"link": "cartTrigger-link-uhZ cartTrigger-trigger-xpY " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["root"] + ""
};
export default ___CSS_LOADER_EXPORT___;
