import styled from "styled-components";
import { breakpoint } from "../../../../utils/breakpoints";
import VideoContainer from "../../../VideoContainer/VideoContainer";
export var SmartVideoComponentStyled = styled.div.withConfig({
  displayName: "Smartstyled__SmartVideoComponentStyled",
  componentId: "sc-hkd7aw-0"
})(["width:100%;background:black;position:relative;", "{padding:0;}", "{.react-player{margin:15px 0;", "{margin:0;}}", "{margin:0;}}.text-wrapper{position:absolute;top:0;bottom:0;margin:0 auto;width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;}h2{font-size:1.75rem;line-height:2.5rem;margin-top:0;margin-bottom:1.5rem;color:#fff;text-align:center;", "{font-size:2.5rem;line-height:3.75rem;margin-bottom:1.5rem;}}p{font-family:'Avenir',\"sans-serif\";font-size:.938rem;line-height:1.875rem;color:#fff;margin-bottom:1.5rem;", "{line-height:2.125rem;font-size:1.125rem;margin-bottom:2rem;}}.image-wrapper{display:flex;justify-content:center;align-items:center;img{max-width:5.3125rem;margin-left:0.9375rem;margin-right:0.9375rem;", "{max-width:6.25rem;}}}"], breakpoint('lg'), VideoContainer, breakpoint('md'), breakpoint('md'), breakpoint('xl'), breakpoint('lg'), breakpoint('xl'));
export var CompleteFreedomComponentStyled = styled.div.withConfig({
  displayName: "Smartstyled__CompleteFreedomComponentStyled",
  componentId: "sc-hkd7aw-1"
})(["margin:6.25rem 0;padding:0 1.875rem;", "{margin:12.5rem 0;}.inner-wrapper{width:100%;margin-left:auto;margin-right:auto;max-width:1100px;position:relative;z-index:2;", "{max-width:1400px;}}.col-1{h2{font-size:1.875rem;margin:0 0 1.875rem;line-height:2.5rem;text-align:center;", "{font-size:3.125rem;margin-bottom:4.375rem;line-height:3.75rem;}}p{font-size:1rem;line-height:1.625rem;margin:0 auto 3.5rem;text-align:center;max-width:63.75rem;", "{font-size:1.875rem;line-height:3.125rem;margin-bottom:7.25rem;}}}.time-widget-wrapper{display:flex;flex-direction:column;", "{flex-direction:row;justify-content:space-between;}.time-widget{display:flex;flex-direction:column;width:100%;max-width:450px;margin:0 auto;", "{width:30%;max-width:unset;margin:0;}.image{margin:0 auto 1.5rem;img{}}.time{font-family:'Avenir',\"sans-serif\";font-weight:700;font-size:1.125rem;line-height:1.5;color:#E87C77;margin-bottom:.625rem;", "{font-size:1.25rem;line-height:1.875rem;}}h3{font-family:'Avenir',\"sans-serif\";font-weight:700;font-size:1.25rem;line-height:1.75;margin-bottom:1.125rem;padding-left:1.125rem;", "{font-size:1.25rem;line-height:1.875rem;margin-bottom:1.875rem;}}p{font-size:1rem;line-height:1.625rem;margin:0 0 4.375rem;text-align:left;padding-left:1.125rem;", "{font-size:1.188rem;line-height:2.125rem;margin:0;}}}}"], breakpoint('lg'), breakpoint('2xl'), breakpoint('xl'), breakpoint('md'), breakpoint('lg'), breakpoint('lg'), breakpoint('md'), breakpoint('md'), breakpoint('lg'));